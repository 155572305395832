import React, {useState} from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';

import LinkButton from './LinkButton';
import AppText from './AppText';
import ChevronRightNavySvg from './svgs/ChevronRightNavySvg';
import TouchView from './TouchView';
import {basicStyles} from '../styles/basic';
import EStyleSheet from 'react-native-extended-stylesheet';

function ActionRowWithAvatar({
	                   style = {},
	                   action,
	                   touchAction,
	                   icon,
	                   title,
	                   slot1 = {label: '', value: ''},
	                   slot2 = {label: '', value: ''},
	                   slot3 = null,
	                   slot4 = null,
	                   children = null,
                   }) {
	const [isMobile, setIsMobile] = useState(false);

	const handleLayout = ({nativeEvent}) => {
		const {width} = nativeEvent.layout;

		if(width > 650) {
			setIsMobile(false);
		} else {
			setIsMobile(true);
		}
	};

	function getContent() {
		return (
			children ?
				(children)
				:
				<>
					<View style={styles.actionRowWithAvatarContainer}>
						<View style={[styles.avatarSection, styles.rowValueContainer]}>
							{
								icon ?
									<View style={styles.avatarWrapper}>
										{icon}
									</View>
									: null
							}
							{
								title ?
									<AppText style={styles.rowTitle}>
										{title}
									</AppText>
									: null
							}
						</View>
						<View style={[styles.actionDetailsSection]}>
							<View style={[
								(slot2.label ? {width : '33.333%'} : {width:'80%', justifyContent: 'flex-end'}),
								styles.rowValueContainer,
								styles.firstRowData,
							]}>
								{
									slot1.label ?
										<AppText style={styles.rowLabel}>
											{slot1.label}
										</AppText>
										:
										null
								}
								<AppText stles={styles.rowValue}>
									{slot1.value}
								</AppText>
							</View>
							<View style={[
								{width: slot4 ? '75%' : (slot3 ? '66.666%' : '50%')},
								(!slot2.label ? {width: '20%', justifyContent: 'flex-end'} : {}),
								styles.secondRowValueContainer,
							]}>
								<View style={[
									{width: (slot4 ? '25%' : (slot3 ? '33.333%' : '50%')), flex:1},
									(!slot2.label ? {display: 'none'} : {}),
									styles.rowValueContainer,
								]}>
									{
										slot2.label ?
										<AppText style={styles.rowLabel}>
										{slot2.label}
										</AppText>
										:
										null
									}
									<AppText stles={styles.rowValue}>
										{slot2.value}
									</AppText>
								</View>
								{
									slot3 ?
										<View style={[
											styles.rowValueContainer,
										]}>
											{
												slot3.label ?
												<AppText style={styles.rowLabel}>
													{slot3.label}
												</AppText>
												:
												null
											}

											<AppText stles={styles.rowValue}>
												{slot3.value}
											</AppText>
										</View>
										:
										null
								}
								{
									slot4 ?
										<View style={[
											styles.rowValueContainer,
										]}>
											{
												slot4.label ?
												<AppText style={styles.rowLabel}>
													{slot4.label}
												</AppText>
												:
												null
											}

											<AppText stles={styles.rowValue}>
												{slot4.value}
											</AppText>
										</View>
										:
										null
								}
								<View style={styles.clickableIndicator}>
									<ChevronRightNavySvg/>
								</View>
							</View>
						</View>
					</View>
				</>
		);
	}

	if (touchAction) {
		return (
			<TouchView
				style={[
					styles.row,
					style,
				]}
				action={() => touchAction()}
			>
				{getContent()}
			</TouchView>
		);
	}

	return (
		<LinkButton onLayout={handleLayout}
		            style={[
			            styles.row,
			            style,
		            ]}
		            action={action}
		>
			{getContent()}
		</LinkButton>
	);
}

export default ActionRowWithAvatar;

const styles = EStyleSheet.create({
	actionRowWithAvatarContainer: {
		flexDirection: 'row',
		flex: 1,
	},
	avatarSection: {
		flexDirection: 'row',
		width: '33.333%',
	},
	actionDetailsSection: {
		flexDirection: 'row',
		width: '66.666%',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		borderWidth: 1,
		borderColor: '#E2E9F4',
		borderRadius: 8,
		backgroundColor: '#FFFFFF',
		paddingRight: 35,
		paddingLeft: 40,
		marginBottom: 10,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 3,
		},
		shadowOpacity: 0.1,
		shadowRadius: 9.11,
		height: 100,
		width: '100%',
	},
	avatarWrapper: {
		height: 100,
		paddingRight: 40,
		borderRightWidth: 1,
		borderRightColor: '#E2E9F4',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 20,
	},
	rowTitle: {
		fontSize: 16,
		color: '#000000',
		fontFamily: 'SourceSansPro-SemiBold',
		whiteSpace: 'nowrap',
	},
	rowLabel: {
		color: '#9BA8CA',
		fontSize: 16,
		marginRight: 10,
		whiteSpace: 'nowrap',
	},
	rowValue: {
		fontSize: 16,
		whiteSpace: 'normal',
	},
	rowValueContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		flex:1,
	},
	secondRowValueContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		//justifyContent: 'space-between',
	},
	'@media (max-width: 1100)': {
		actionRowWithAvatarContainer: {
			flexDirection: 'column'
		},
		row: {
			height: 'auto',
			paddingRight: 0,
			paddingLeft: 0,
		},
		avatarSection: {
			width: '100%',
			borderBottomWidth: 1,
			borderBottomColor: '#E2E9F4',
			paddingRight: 35,
			paddingLeft: 40,
		},
		avatarWrapper: {
			borderRightWidth: 0,
			borderRightColor: '#E2E9F4',
			paddingRight: 10,
		},
		actionDetailsSection: {
			width: '100%',
			paddingTop: 30,
			paddingRight: 35,
			paddingLeft: 40,
			paddingBottom: 30,
		},
		clickableIndicator: {
			display: 'none'
		},
		firstRowData: {
			justifyContent: 'flex-start'
		}
	},
	'@media (max-width: 850)': {
		actionDetailsSection: {
			flexDirection: 'column'
		},
		secondRowValueContainer: {
			width: '100%',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-start'
		},
		rowValueContainer: {
			width: '100%',
			paddingTop: 10,
		},
		firstRowData: {
			paddingTop: 0
		}
	}
});
