import React, {useEffect, useState} from 'react';
import {View, ScrollView} from 'react-native';
import {useDebouncedCallback} from 'use-debounce';
import {connect} from 'react-redux';

import AppText from "../../../components/AppText";
import AppButton from "../../../components/AppButton";
import SurveyResponsesService from "../../../services/SurveyResponsesService";
import moment from "moment";
import {basicStyles} from "../../../styles/basic";
import ReportsSearchSection from "../../../components/ReportsSearchSection";
import PageContentContainer from "../../../components/PageContentContainer";
import ContentSection from "../../../components/ContentSection";
import SpreadSheet from "../../../components/SpreadSheet";
import {API_ROOT} from "../../../config";
import Pagination from "../../../components/Pagination";
import store from '../../../store';
import {setReviewResponse} from '../../../store/reviewResponse/reviewResponseActions';
import ChevronHorizontalBlackSvg from "../../../components/svgs/ChevronHorizontalBlackSvg";
import SurveysService from "../../../services/SurveysService";
import {formatDateAmPm} from "../../../utilities/methods";
import EStyleSheet from "react-native-extended-stylesheet";
import {CommonActions} from "@react-navigation/native";
import LinkButton from '../../../components/LinkButton';
import InteriorTabNavLink from "../../../components/InteriorTabNavLink";
import MapSvg from "../../../components/svgs/MapSvg";
import LinesSvg from "../../../components/svgs/LinesSvg";
import ThirdPartyReviews from "./ThirdPartyReviews";

function FluidReviews(props) {
    const surveyResponseId = props.route.params?.surveyResponseId;
    const [surveyResponses, setSurveyResponses] = useState([]);
    const perPage = 10;
    const [surveyCount, setSurveyCount] = useState(0);
    const [paginationRange, setPaginationRange] = useState([1]);
    const [disabledQuestions, setDisabledQuestions] = useState(['quality-rating','cost-rating','convenience-rating']);
    const [disabledQuestionsReady, setDisabledQuestionsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const {navigate} = props.navigation;

    let today = new Date();
    let startDate = props.route?.params?.startDate ? new Date(props.route.params.startDate).setHours(12) : new Date(new Date().setDate(today.getDate() - 30)).setHours(12, 0, 0, 0);
    let endDate = props.route?.params?.endDate ? new Date(props.route.params.endDate).setHours(12) : today.setHours(12, 0, 0, 0);
    let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;
    let search = props.route?.params?.search ? props.route.params.search : '';

    const [getSurveyResponsesDebounce] = useDebouncedCallback(() => {
        setIsLoading(true);
        SurveyResponsesService.getSurveyResponses(
            props.auth.organization.organization_id,
            search,
            null,
            null,
            true,
            startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            perPage,
            currentPage
        ).then(surveyResponsesData => {
            setSurveyResponses(surveyResponsesData.surveyResponses);

            if(surveyResponseId) {
                let surveyResonse = surveyResponsesData.surveyResponses?.find(el => el.survey_response_id == surveyResponseId);
                reviewResponseCallback(surveyResonse);
            }

            setDisabledSubQuestions(surveyResponsesData.surveyResponses);
            setSurveyCount(surveyResponsesData.surveyCount);
            setPaginationRange(surveyResponsesData.paginationRange);
            setIsLoading(false);
        });
    });

    const reviewResponseCallback = async (data) => {
        var surveyResponseAnswer = data?.surveyAnswers.find(el => el.survey_question_token = 'review' && el.survey_question_type_token == 'text-long');

        if(!surveyResponseAnswer?.survey_response_answer_id) {
            return;
        }

        var surveyReviewResponse = await SurveysService.getReviewResponse(surveyResponseAnswer.survey_response_answer_id);

        store.dispatch(setReviewResponse({
            active: true,
            review: data.review,
            response: (surveyReviewResponse?.survey_review_response_text ? surveyReviewResponse.survey_review_response_text : ''),
            userId: data.user_id,
            reviewerName: data['screen-name'],
            surveyResponseId: data.survey_response_id,
            surveyResponseAnswerId: surveyResponseAnswer.survey_response_answer_id,
            organizationTitle: props.auth?.organization?.organization_title,
            jobId: data.job_id,
            jobCustomerEmail: data.job_customer_email,
            jobCustomerPhone: data.job_customer_phone,
            displayJobButton: true,
            allowAiGeneratedResponse: data.organization_allow_ai_automatic_review_response === '1'
        }));
    };

    const setDisabledSubQuestions = (surveyResponses) => {
        let results = [...disabledQuestions];

        if(surveyResponses && surveyResponses.length > 0) {
            let surveyQuestions = surveyResponses[0]['surveyQuestions'];

            surveyQuestions.forEach((e) => {
                let questionIndex = results.indexOf(e.survey_question_token);
                if(questionIndex > -1) {
                    results.splice(questionIndex,1);
                }
            })
        }
        setDisabledQuestions(results);
        setDisabledQuestionsReady(true);
    };

    const columns = [
        {
            key: 'job_id',
            exportName: 'job_id',
            label: 'Job ID',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_id'],
            onChangeText() {
            },
        },
        {
            key: 'job_timestamp',
            exportName: 'job_timestamp',
            label: 'Job Created Timestamp',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['job_timestamp']),
            onChangeText() {
            },
        },
        {
            key: 'job_customer_name',
            exportName: 'job_customer_name',
            label: 'Customer Name',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_name'],
            onChangeText() {
            },
        },
        {
            key: 'screen-name',
            exportName: 'screen-name',
            label: 'Screen Name',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['screen-name'],
            onChangeText() {
            },
        },
        {
            key: 'overall-rating',
            exportName: 'overall-rating',
            label: 'Overall Rating',
            type: 'range',
            disabled: true,
            valueExtractor: review => review['overall-rating'],
            onChange() {
            },
            sortCast: 'int',
        },
        {
            key: 'review',
            exportName: 'review',
            label: 'Review',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['review'],
            onChangeText() {
            },
        },
        {
            key: 'quality-rating',
            exportName: 'quality-rating',
            label: 'Quality Rating',
            type: 'range',
            disabled: true,
            valueExtractor: review => review['quality-rating'],
            onChange() {
            },
            sortCast: 'int',
        },
        {
            key: 'cost-rating',
            exportName: 'cost-rating',
            label: 'Cost Rating',
            type: 'range',
            disabled: true,
            valueExtractor: review => review['cost-rating'],
            onChange() {
            },
            sortCast: 'int',
        },
        {
            key: 'convenience-rating',
            exportName: 'convenience-rating',
            label: 'Convenience Rating',
            type: 'range',
            disabled: true,
            valueExtractor: review => review['convenience-rating'],
            onChange() {
            },
            sortCast: 'int',
        },
        {
            key: 'survey_response_create_timestamp',
            exportName: 'survey_response_create_timestamp',
            label: 'Survey Request Timestamp',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_create_timestamp_local']),
            onChangeText() {
            },
        },
        {
            key: 'survey_response_viewed',
            exportName: 'survey_response_viewed',
            label: 'Survey Viewed',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_viewed_local']),
            onChangeText() {
            },
        },
        {
            key: 'user_email',
            exportName: 'user_email',
            label: 'Team Member Email',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['user_email'],
            onChangeText() {
            },
        },
        {
            key: 'job_customer_email',
            exportName: 'job_customer_email',
            label: 'Customer Email',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_email'],
            onChangeText() {
            },
        },
        {
            key: 'survey_response_email_opened_timestamp',
            exportName: 'survey_response_email_opened_timestamp',
            label: 'Email Opened',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_email_opened_timestamp_local']),
            onChangeText() {
            },
        },
        {
            key: 'survey_response_email_permanent_failure_timestamp',
            exportName: 'survey_response_email_permanent_failure_timestamp',
            label: 'Email Failure',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_email_permanent_failure_timestamp_local']),
            onChangeText() {
            },
        },
        {
            key: 'survey_response_email_permanent_failure_reason',
            exportName: 'survey_response_email_permanent_failure_reason',
            label: 'Email Failure Reason',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['survey_response_email_permanent_failure_reason'],
            onChangeText() {
            },
        },
        {
            key: 'job_customer_phone',
            exportName: 'job_customer_phone',
            label: 'Customer Phone',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_phone'],
            onChangeText() {
            },
        },
        {
            key: 'survey_response_text_error_timestamp',
            exportName: 'survey_response_text_error_timestamp',
            label: 'Text Message Failure',
            type: 'text',
            disabled: true,
            valueExtractor: review => formatDateAmPm(review['survey_response_text_error_timestamp_local']),
            onChangeText() {
            },
        },
        {
            key: 'survey_response_text_error_reason',
            exportName: 'survey_response_text_error_reason',
            label: 'Text Failure Reason',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['survey_response_text_error_reason'],
            onChangeText() {
            },
        },
        {
            key: 'survey_review_response_text',
            exportName: 'survey_review_response_text',
            label: 'Company Response',
            type: 'text',
            disabled: true,
            valueExtractor: review => (review['surveyReviewResponse']?.length ? review['surveyReviewResponse'][0]['survey_review_response_text']  : ''),
            onChangeText() {
            },
        },
        {
            key: 'ai_generated_requests',
            exportName: 'ai_generated_requests',
            label: 'AI Assistance',
            type: 'text',
            disabled: true,
            valueExtractor: review => (review['surveyReviewResponse']?.length ? review['surveyReviewResponse'][0]['ai_generated_requests']  : ''),
            onChangeText() {
            },
        },
        {
            key: 'responding_team_member_email',
            exportName: 'responding_team_member_email',
            label: 'Team Member Email',
            type: 'text',
            disabled: true,
            valueExtractor: review => (review['surveyReviewResponse']?.length ? review['surveyReviewResponse'][0]['survey_review_response_text']  : ''),
            onChangeText() {
            },
        },

        {
            exportable: false,
            key: 'link',
            label: 'More View',
            type: 'custom',
            valueExtractor(data) {
            },
            render(review) {
                return (
                    <AppButton
                        action={() => {
                            reviewResponseCallback(review)
                        }}
                        style={[
                            {
                                backgroundColor: '#0065DB',
                                padding: 10,
                                borderRadius: 5,
                            },
                            basicStyles.flexRow,
                            basicStyles.flexCenterContent,
                        ]}
                    >
                        <AppText style={{
                            color: '#FFFFFF',
                            marginRight: 10,
                        }}>
                            Respond
                        </AppText>
                        <ChevronHorizontalBlackSvg fill="#FFFFFF" direction="right"/>
                    </AppButton>
                );
            },
        },
        {
            key: 'store_front_title',
            exportName: 'store_front_title',
            label: 'Group Name',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['store_front_title'],
            onChangeText() {
            },
        },
        {
            key: 'job_customer_address',
            exportName: 'job_customer_address',
            label: 'Customer Street Address 1',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_address'],
            onChangeText() {
            },
        },
        {
            key: 'job_customer_address',
            exportName: 'job_customer_address',
            label: 'Customer Street Address 2',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_address'],
            onChangeText() {
            },
        },
        {
            key: 'job_customer_city',
            exportName: 'job_customer_city',
            label: 'Customer City',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_city'],
            onChangeText() {
            },
        },
        {
            key: 'job_customer_state',
            exportName: 'job_customer_state',
            label: 'Customer State',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_state'],
            onChangeText() {
            },
        },
        {
            key: 'job_customer_zip',
            exportName: 'job_customer_zip',
            label: 'Customer Zip',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_customer_zip'],
            onChangeText() {
            },
        },
        {
            key: 'job_product_type',
            exportName: 'job_product_type',
            label: 'Product Type',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_product_type'],
            onChangeText() {
            },
        },
        {
            key: 'job_product_brand',
            exportName: 'job_product_brand',
            label: 'Product Brand',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_product_brand'],
            onChangeText() {
            },
        },
        {
            key: 'job_product_model_number',
            exportName: 'job_product_model_number',
            label: 'Product Model Number',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_product_model_number'],
            onChangeText() {
            },
        },
        {
            key: 'job_notes',
            exportName: 'job_notes',
            label: 'Job Notes',
            type: 'text',
            disabled: true,
            valueExtractor: review => review['job_notes'],
            onChangeText() {
            },
        },
        // {
        //     key: 'survey_response_responded_timestamp',
        //     exportName: 'survey_response_responded_timestamp',
        //     label: 'Completed Date',
        //     type: 'text',
        //     disabled: true,
        //     valueExtractor: review => formatDateAmPm(review['survey_response_responded_timestamp_local']),
        //     onChangeText() {
        //     },
        // },
    ];

    useEffect(() => {
        getSurveyResponsesDebounce();
    }, [props.auth.organization.organization_id, search, startDate, endDate, currentPage]);

    return (
            <>
                <PageContentContainer style={[{zIndex: 10}]}>
                    <ContentSection style={[
                        basicStyles.spreadSheetWrapper,
                        basicStyles.flexScale,
                        styles.reviewsContentWrapper,
                    ]}>
                        {
                            <SpreadSheet
                                filterRoute="Reviews"
                                rowCount={surveyCount}
                                rows={surveyResponses}
                                columns={columns}
                                rowKeyExtractor="survey_response_id"
                                exportUrl={API_ROOT + '/organizations/' + props.auth.organization.organization_id + '/survey-responses?completedOnly=1&search=' + encodeURIComponent(search) + '&completedAfterDate=' + encodeURIComponent(startDate ? moment(startDate).format('YYYY-MM-DD') : '') + '&completedBeforeDate=' + (encodeURIComponent(endDate ? moment(endDate).format('YYYY-MM-DD') : '')) + '&perPage=' + perPage + '&page=' + currentPage}
                                exportTitle="reviews.csv"
                                initialDisabledColumns={disabledQuestions}
                                searchElement={
                                    <ReportsSearchSection
                                        onChangeSearch={(value) => {
                                            navigate('FluidReviews', {
                                                ...props.route?.params,
                                                currentPage: 1,
                                                search: value
                                            });
                                        }}
                                        searchValue={search}
                                        onChangeStartDate={(value) => {
                                            navigate('FluidReviews', {
                                                ...props.route?.params,
                                                startDate: moment(value).format('MM-DD-YYYY'),
                                                currentPage: 1
                                            })
                                        }}
                                        startDateValue={startDate}
                                        onChangeEndDate={(value) => {
                                            navigate('FluidReviews', {
                                                ...props.route?.params,
                                                endDate: moment(value).format('MM-DD-YYYY'),
                                                currentPage: 1
                                            })
                                        }}
                                        endDateValue={endDate}
                                        onChangeDates={(dates) => {
                                            const [start, end] = dates;
                                            navigate('FluidReviews', {
                                                ...props.route?.params,
                                                startDate: moment(start).format('MM-DD-YYYY'),
                                                endDate: moment(end).format('MM-DD-YYYY'),
                                                currentPage: 1
                                            })
                                        }}
                                    />
                                }
                                navTab={<View style={[basicStyles.flexRow, basicStyles.alignContentCenter, styles.styleLinkContainer]}>
                                    <InteriorTabNavLink
                                        to={'/FluidReviews'}
                                        action={CommonActions.navigate('FluidReviews')}
                                        active={true}
                                        icon={<LinesSvg fill={'#ffffff'}/>}
                                        label={'Fluid Reviews'}
                                        style={{
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                        }}
                                    />
                                    <InteriorTabNavLink
                                        to={'/ThirdPartyReviews'}
                                        action={CommonActions.navigate('ThirdPartyReviews')}
                                        active={false}
                                        icon={<MapSvg fill={'#0065db'}/>}
                                        label={'Other Reviews'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                        }}
                                    />
                                </View>}
                                isLoading={isLoading}
                            />
                        }
                    </ContentSection>
                </PageContentContainer>
                <Pagination screenName="FluidReviews" pageCount={Math.ceil(surveyCount / perPage)} pageIndex={currentPage} paginationRange={paginationRange}/>
            </>
    )
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(FluidReviews);

const styles = EStyleSheet.create({
    styleLinkContainer: {
        marginRight: 10,
    },
    '@media (max-width: 1100)': {
        styleLinkContainer: {
            marginRight: 0
        }
    },
    '@media (max-width: 630)': {
        styleContainer: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    '@media (max-width: 500)': {
        styleLinkContainer: {
            position: 'absolute',
            right: 60,
            top: -90,
        },
        reviewsContentWrapper: {
            marginTop: 30,
        }
    },
    '@media (max-width: 450)': {
        styleTitle: {
            fontSize: 20,
        },

    }
});
