import {Dimensions} from 'react-native';
import moment from 'moment';
import store from '../store';
import {FLUID_ROLE_IDS} from "../config";

export const formatDate = function (dateString, toFormat = 'MM/DD/YYYY', fromFormat = 'YYYY-MM-DD HH:mm:ss') {
    return moment.utc(dateString, fromFormat).local().format(toFormat);
};

export const formatDateAmPm = function (dateString) {
    if(!dateString) { return ''; }
    var date = new Date(dateString);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + hours + ':' + minutes + ' ' + ampm;
};

export const formatUtcTimeToLocalTime = function (timeString) {
    //expected timeString format 00:00:00
    if (!timeString) { return ''; }

    let time = timeString.split(':');
    let hour = parseInt(time[0]);
    let minute = time[1];

    let date = new Date();
    date.setUTCHours(hour, minute);

    hour = date.getHours();
    minute = date.getMinutes();

    if(minute < 10) {
        minute = ('0' + minute);
    }

    let amPm = 'am';
    if(hour > 12) {
        hour = (hour - 12);
        amPm = 'pm';
    } else if (hour == 12) {
        amPm = 'pm';
    }

    hour = (hour + '');

    return {
        hour: hour,
        minute: minute,
        amPm: amPm
    }
};

export const timestampToFormattedDate = function (timestamp, format = 'DD MMMM YYYY') {
    return moment.unix(timestamp).format(format);
};

export const getDate = function (toFormat = 'MM/DD/YYYY') {
    return moment().format(toFormat);
};

export const checkTimestampAfter = function (timestamp, value, units = 'months') {
    return moment.unix(timestamp).isAfter(moment().subtract(value, units));
};

export const getScreenMaxHeight = () => {
    const state = store.getState();
    //todo: update based on screen width
    let headerHeight = 145;

    if(state.auth.realToken) {
        headerHeight = headerHeight + 60;
    }

    if(FLUID_ROLE_IDS.includes(state.auth.organizationRoleId)) {
        headerHeight = headerHeight + 39;
    }

    const windowHeight = Dimensions.get('window').height;

    return windowHeight - headerHeight;
};

export const checkIfFluidUser = () => {
    const state = store.getState();
    return FLUID_ROLE_IDS.includes(state.auth.organizationRoleId)
}

export const getUnauthenticatedScreenMaxHeight = () => {
    //todo: update based on screen width
    let headerHeight = 71;

    const windowHeight = Dimensions.get('window').height;

    return windowHeight - headerHeight;
};

export const hexToRgb = (hex) => {
    if (hex === null || hex === undefined) {
        return null;
    }

    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    } : null;
};

export const capitalizeString = ([firstLetter, ...rest]) => {
    return [firstLetter.toUpperCase(), ...rest.map(letter => letter.toLowerCase())].join('');
};

export const getJobSurveyStatus = (job) => {
    if (job.surveyResponse) {
        if (job.surveyResponse.survey_response_responded_timestamp) {
            return 'Completed - ' + formatDate(job.surveyResponse.survey_response_responded_timestamp, 'MM/DD/YYYY hh:mm A');
        } else if (job.surveyResponse.survey_response_email_opened_timestamp) {
            return 'Opened - ' + formatDate(job.surveyResponse.survey_response_email_opened_timestamp, 'MM/DD/YYYY hh:mm A');
        } else if (job.surveyResponse.survey_response_email_delivered_timestamp) {
            return 'Delivered ' + formatDate(job.surveyResponse.survey_response_email_delivered_timestamp, 'MM/DD/YYYY hh:mm A');
        } else if (job.surveyResponse.survey_response_text_error_reason) {
            const reason = JSON.parse(job.surveyResponse.survey_response_text_error_reason);
            return `Unable to Send Survey - ${reason.errorMessage} - ${formatDate(job.surveyResponse.survey_response_text_error_timestamp, 'MM/DD/YYYY hh:mm A')}`;

        } else {
            //Note: just because a survey response exists, doesn't necessarily mean the survey was actually sent.
            // There are a couple of things that could still go wrong in the backend between the time the survey response is created and the email/text is sent
            return 'Sent - ' + formatDate(job.surveyResponse.survey_response_create_timestamp, 'MM/DD/YYYY hh:mm A');
        }
    } else if (job.scheduled_survey_response_id) {
        if(job.scheduled_survey_response_status == 'Scheduled') {
            let scheduledTime = formatUtcTimeToLocalTime(job.scheduled_survey_time);
            return 'Scheduled - ' + scheduledTime.hour + ':' +scheduledTime.minute + (scheduledTime.amPm == 'am' ? ' AM' : ' PM');
        } else {
            return job.scheduled_survey_response_status === "Finished" ? "Not Sent" : job.scheduled_survey_response_status;
        }
    }  else {
        return 'Not Sent';
    }
};

export const getUserDisplayName = (user) => {
    return user.profile_first_name + (user.profile_last_name ? ' ' + user.profile_last_name : '');
};

export const objectFlip = (obj) => {
    const ret = {};
    Object.keys(obj).forEach(key => {
        ret[obj[key]] = key;
    });
    return ret;
}

export const getMinuetsSinceDate = (dateString, fromFormat = 'YYYY-MM-DD HH:mm:ss') => {
    const moment1 = moment.utc(dateString, fromFormat);
    const moment2 = moment.utc();
    const diffMilliSecs = moment2.diff(moment1);
    const diffMinuets = diffMilliSecs / 1000 / 60;

    return Math.round(diffMinuets);
}

export const formatPhoneNumber = (phoneNumberString) =>  {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
};

export const getDateDifferenceInSeconds = (dateString1, dateString2) => {
    const moment1 = moment.utc(dateString1, 'YYYY-MM-DD HH:mm:ss');
    const moment2 = moment.utc(dateString2, 'YYYY-MM-DD HH:mm:ss');

    return Math.round(moment1.diff(moment2) / 1000);
};

export const generateUuid = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
};
