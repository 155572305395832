import React, {useState, useMemo, useCallback, useEffect, useRef} from 'react';
import {ScrollView, View, TouchableOpacity, ActivityIndicator} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import {CancelToken} from "axios";
import structuredClone from '@ungap/structured-clone';

import Modal from "../../../components/Modal";
import MessagesService from "../../../services/MessagesService";
import ContentSection from "../../../components/ContentSection";
import AppText from "../../../components/AppText";
import {textStyles} from "../../../styles/text";
import AppTextInput from "../../../components/AppTextInput";
import {basicStyles} from "../../../styles/basic";
import AppButton from "../../../components/AppButton";
import AppPickerInput from "../../../components/AppPickerInput";
import AddressInput from "../../../components/AddressInput";
import AppDatePicker from "../../../components/AppDatePicker";
import MoneyInput from "../../../components/MoneyInput";
import moment from "moment";

function ScriptModal({onSubmit, onClose, organizationId, active = false}) {
    const [categoriesFetched, setCategoriesFetched] = useState(false);
    const [categories, setCategories] = useState([]);
    const [scripts, setScripts] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [scriptId, setScriptId] = useState('');
    const [scriptParams, setScriptParams] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [outputPreview, setOutputPreview] = useState('');
    const outputPreviewTimeOut = useRef(null);
    const fetchOutputPreviewDataSource = useRef(null);
    const [fetchingPreview, setFetchingPreview] = useState(false);

    const selectedScript = useMemo(() => {
        if (scriptId) {
            const scriptIndex = scripts.findIndex(script => {
                return script.message_script_id === scriptId;
            })

            return scripts[scriptIndex];
        }

        return {variables: []};
    }, [scriptId]);

    const updateScriptParams = useCallback((prop, value, index) => {
        setScriptParams(current => {
            let update = {...current};
            if(update[prop]) {
                update[prop][index] = value
            } else {
                update[prop] = [];
                update[prop][index] = value
            }

            return update;
        })
    }, []);

    const handleClose = useCallback(() => {
        onClose();
        setScripts([]);
        setCategoryId('');
        setScriptId('');
        setScriptParams({});
        setIsSubmitting(false);
        setOutputPreview('');
    }, [onClose]);

    const scriptParamsFormatted = useMemo(() => {
        const scriptParamsFormatted = structuredClone(scriptParams);
        for (let scriptParamProp in scriptParamsFormatted) {
            if (scriptParamsFormatted.hasOwnProperty(scriptParamProp)) {
                const variableIndex = selectedScript.variables.findIndex(variable => {
                    return variable.message_script_variable_title === scriptParamProp;
                });
                if(variableIndex >= 0) {
                    if (parseInt(selectedScript.variables[variableIndex].message_script_variable_allow_multiple) === 1) {
                        // If variable is allow multiple, don't assign as multidimensional array
                        if (selectedScript.variables[variableIndex].message_script_variable_type_token === 'date') {
                            scriptParamsFormatted[scriptParamProp] = moment(scriptParamsFormatted[scriptParamProp][0]).format('MM/DD/YYYY');
                        } else {
                            scriptParamsFormatted[scriptParamProp] = scriptParamsFormatted[scriptParamProp][0];
                        }
                    } else {
                        if (selectedScript.variables[variableIndex].message_script_variable_type_token === 'date') {
                            for (const [index, scriptParamPropValue] of scriptParamsFormatted[scriptParamProp].entries()) {
                                scriptParamsFormatted[scriptParamProp][index] = moment(scriptParamPropValue).format('MM/DD/YYYY');
                            }
                        }
                    }
                }
            }
        }

        return scriptParamsFormatted;
    }, [scriptParams, selectedScript.variables]);

    const handleSubmit = useCallback(() => {
        MessagesService.getScriptOutput(
            organizationId,
            scriptId,
            scriptParamsFormatted
        )
            .then(output => {
                onSubmit(output);
                handleClose();
            }).catch(e => {
            if (!e.__CANCEL__) {
                console.log('Error fetching script output: ', e);
            }
        })
    }, [organizationId, scriptId, handleClose, scriptParamsFormatted]);

    const fetchOutputPreview = useCallback(() => {
        if (outputPreviewTimeOut.current) {
            clearTimeout(outputPreviewTimeOut.current);
            outputPreviewTimeOut.current = null;
        }

        if (fetchOutputPreviewDataSource.current) {
            fetchOutputPreviewDataSource.current.cancel();
        }

        if(scriptId) {
            setFetchingPreview(true);
            fetchOutputPreviewDataSource.current = CancelToken.source();

            outputPreviewTimeOut.current = setTimeout(() => {
                MessagesService.getScriptOutput(
                    organizationId,
                    scriptId,
                    scriptParamsFormatted,
                    fetchOutputPreviewDataSource.current.token
                ).then(output => {
                    setOutputPreview(output);
                    setFetchingPreview(false);
                }).catch(e => {
                    if (!e.__CANCEL__) {
                        console.log('Error fetching script output: ', e);
                    }
                    setFetchingPreview(false);
                });
            }, 1500)
        } else {
            setOutputPreview('');
            setFetchingPreview(false);
        }
    }, [organizationId, scriptId, scriptParamsFormatted]);

    useEffect(() => {
        MessagesService.getScriptCategories(organizationId)
            .then(categories => {
                setCategoriesFetched(true);
                setCategoryId('');
                setCategories(categories);
            })
    }, [organizationId]);

    useEffect(() => {
        if (categoryId) {
            MessagesService.getMessageScripts(organizationId, {
                message_script_category_id: categoryId,
                message_script_active: 1
            }).then(scripts => {
                setScriptId('');
                setScripts(scripts);
            })
        } else {
            setScriptId('');
            setScripts([]);
        }
    }, [organizationId, categoryId]);

    useEffect(() => {
        fetchOutputPreview();
    }, [organizationId, scriptId, scriptParamsFormatted]);

    return (
        <Modal
            active={active}
            onClose={handleClose}
            closeOnOverlayClick={false}
        >
            <ContentSection
                style={[styles.contentWrapper]}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <ScrollView>
                    <AppText style={[textStyles.pageTitle, {marginBottom: 10}]}>
                        Configure Script
                    </AppText>
                    {
                        !categoriesFetched ?
                            <ActivityIndicator size="large" color="#467AFF"/>
                            : null
                    }
                    {
                        categories.length > 0 ?
                            <AppPickerInput
                                label="Category"
                                selectedValue={categoryId}
                                onValueChange={value => {
                                    setCategoryId(value);
                                }}
                                items={categories}
                                labelExtractor="message_script_category_title"
                                valueExtractor="message_script_category_id"
                                wrapperStyle={styles.inputWrapper}
                            />
                            :
                            null
                    }
                    {
                        categoriesFetched && categories.length === 0 ?
                            <View style={[basicStyles.flexScale]}>
                                <AppText style={[styles.noScriptsText]}>
                                    No Scripts Found
                                </AppText>
                            </View>
                            : null
                    }
                    {
                        categoryId && scripts.length > 0 ?
                            <AppPickerInput
                                label="Script"
                                selectedValue={scriptId}
                                onValueChange={value => {
                                    setScriptId(value);
                                    setScriptParams({});
                                }}
                                items={scripts}
                                labelExtractor="message_script_title"
                                valueExtractor="message_script_id"
                                wrapperStyle={styles.inputWrapper}
                            />
                            : null
                    }
                    {
                        scriptId ?
                            <>
                                {
                                    selectedScript.variables.reduce((accumulated, variable) => {
                                        if(parseInt(variable.message_script_variable_allow_multiple) === 1) {
                                            accumulated.push({...variable, index: 0});
                                        } else {
                                            const count = variable[variable.message_script_variable_title + '_count'];
                                            for (let i = 0; i < count; i++) {
                                                accumulated.push({
                                                    ...variable,
                                                    index: i
                                                });
                                            }
                                        }

                                        return accumulated;
                                    }, []).
                                    map((variable) => {
                                        // Add note above field, add links they exist
                                        let prepend = null;
                                        if  (variable.message_script_variable_note) {
                                            const matches = variable.message_script_variable_note.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                                            prepend = variable.message_script_variable_note;
                                            if (matches) {
                                                let output = <></>;
                                                for (let match of matches) {
                                                    let matchIndex = prepend.indexOf(match);
                                                    prepend = prepend.replace(match, '');
                                                    output = <>
                                                        {output}
                                                        {prepend.substring(0, matchIndex)}
                                                        <a href={match} target={"_blank"}>{match}</a>
                                                    </>;
                                                    prepend = prepend.slice(matchIndex);
                                                }
                                                prepend = <>
                                                    {output}
                                                    {prepend.substring(0)}
                                                </>;
                                            }
                                            prepend = <AppText style={styles.notesText}>
                                                {prepend}
                                            </AppText>;
                                        }
                                        switch (variable.message_script_variable_type_token) {
                                            case 'text':
                                                return (
                                                    <>
                                                        {prepend}
                                                        <AppTextInput
                                                            key={`${variable.message_script_variable_id}-${variable.index}`}
                                                            label={variable.message_script_variable_title}
                                                            value={scriptParams[variable.message_script_variable_title]?.[variable.index] || ''}
                                                            onChangeText={value => {
                                                                updateScriptParams(variable.message_script_variable_title, value, variable.index);
                                                            }}
                                                            wrapperStyle={styles.inputWrapper}
                                                        />
                                                    </>
                                                );
                                            case 'dropdown':
                                                return (
                                                    <>
                                                        {prepend}
                                                        <AppPickerInput
                                                            key={`${variable.message_script_variable_id}-${variable.index}`}
                                                            label={variable.message_script_variable_title}
                                                            selectedValue={scriptParams[variable.message_script_variable_title]?.[variable.index] || ''}
                                                            onValueChange={value => {
                                                                updateScriptParams(variable.message_script_variable_title, value, variable.index);
                                                            }}
                                                            items={variable.variableOptions.map(variableOption => {
                                                                return {value: variableOption.message_script_variable_option_value}
                                                            })}
                                                            labelExtractor="value"
                                                            valueExtractor="value"
                                                            wrapperStyle={styles.inputWrapper}
                                                        />
                                                    </>
                                                );
                                            case 'address':
                                                return (
                                                    <>
                                                        {prepend}
                                                        <AddressInput
                                                            key={`${variable.message_script_variable_id}-${variable.index}`}
                                                            label={variable.message_script_variable_title}
                                                            value={scriptParams[variable.message_script_variable_title]?.[variable.index] || ''}
                                                            onChangeAddress={value => {
                                                                updateScriptParams(variable.message_script_variable_title, value, variable.index);
                                                            }}
                                                            wrapperStyle={styles.inputWrapper}
                                                            alterZIndexOnFocus={true}
                                                        />
                                                    </>
                                                );
                                            case 'date':
                                                return (
                                                    <>
                                                        {prepend}
                                                        <AppDatePicker
                                                            key={`${variable.message_script_variable_id}-${variable.index}`}
                                                            label={variable.message_script_variable_title}
                                                            selectedDate={scriptParams[variable.message_script_variable_title]?.[variable.index] || ''}
                                                            onChange={value => {
                                                                updateScriptParams(variable.message_script_variable_title, value, variable.index);
                                                            }}
                                                            wrapperStyle={styles.inputWrapper}
                                                            alterZIndexOnFocus={true}
                                                        />
                                                    </>
                                                );
                                            case 'dollar':
                                                return (
                                                    <>
                                                        {prepend}
                                                        <MoneyInput
                                                            key={`${variable.message_script_variable_id}-${variable.index}`}
                                                            label={variable.message_script_variable_title}
                                                            value={scriptParams[variable.message_script_variable_title]?.[variable.index] || ''}
                                                            onChangeText={value => {
                                                                updateScriptParams(variable.message_script_variable_title, value, variable.index);
                                                            }}
                                                            wrapperStyle={styles.inputWrapper}
                                                        />
                                                    </>
                                                );
                                        }
                                    })
                                }
                            </>
                            : null
                    }
                    {
                        scriptId ?
                            <AppTextInput
                                label="Output Preview"
                                value={outputPreview}
                                disabled={true}
                                multiline={true}
                                lines={5}
                                wrapperStyle={{height: 'auto'}}
                                inputStyle={{minHeight: 90, overflowY: 'auto'}}
                                loading={fetchingPreview}
                            />
                            :
                            null
                    }
                    <View style={[basicStyles.flexRow, basicStyles.justifyContentFlexEnd, {marginTop: 20}]}>
                        <AppButton
                            label="Cancel"
                            action={handleClose}
                            style={{marginRight: 10}}
                            theme="transBlue"
                            disabled={isSubmitting}
                        />
                        <AppButton
                            label="Confirm"
                            action={handleSubmit}
                            disabled={isSubmitting}
                        />
                    </View>
                </ScrollView>
            </ContentSection>
        </Modal>
    );
}

export default ScriptModal;

const styles = EStyleSheet.create({
    contentWrapper: {
        padding: 32,
        width: 600,
        maxWidth: '100%',
        maxHeight: '100vh'
    },
    inputWrapper: {
        marginBottom: 10
    },
    noScriptsText: {
        color: 'rgba(158, 171, 204, 1)',
        textAlign: 'center',
        paddingRight: 15,
        paddingLeft: 15,
    },
    notesText: {
        fontSize: 14,
        color: '#9BA8CA',
        marginBottom: 5,
    }
});
