import React, {useEffect, useState} from 'react';
import {View, ScrollView} from 'react-native';
import {CommonActions} from '@react-navigation/native';
import {useDebouncedCallback} from 'use-debounce';
import {connect} from 'react-redux';

import SpreadSheet from '../../components/SpreadSheet';
import {basicStyles} from '../../styles/basic';
import PageTop from '../../components/PageTop';
import {textStyles} from '../../styles/text';
import AppText from '../../components/AppText';
import PageContentContainer from '../../components/PageContentContainer';
import ContentSection from '../../components/ContentSection';
import JobsService from '../../services/JobsService';
import UsersService from '../../services/UsersService';
import LinkButton from '../../components/LinkButton';
import ChevronHorizontalBlackSvg from '../../components/svgs/ChevronHorizontalBlackSvg';
import Avatar from '../../components/Avatar';
import {API_ROOT, AVATAR_DEFAULT, AVATAR_PATH_ORG, ORGANIZATION_TYPE_IDS} from '../../config';
import moment from 'moment';
import ReportsSearchSection from '../../components/ReportsSearchSection';
import Pagination from "../../components/Pagination";
import {formatDateAmPm} from "../../utilities/methods";

function Customers(props) {
	const [jobs, setJobs] = useState([]);
	const [users, setUsers] = useState([]);
	const {organization} = props.auth;
	const perPage = 10;
	const [paginationRange, setPaginationRange] = useState([1]);
	const [jobCount, setJobCount] = useState(0);

	const {navigate} = props.navigation;

	// console.log(props);
	// console.log(props.route.params.startDate);

	let startDate = props.route?.params?.startDate ? new Date(props.route.params.startDate).setHours(12) : new Date(moment().subtract(30, 'days').format('YYYY-MM-DD'));
	let endDate = props.route?.params?.endDate ? new Date(props.route.params.endDate).setHours(12) : new Date(moment().format('YYYY-MM-DD'));
	let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;
	let search = props.route?.params?.search ? props.route.params.search : '';

	// props.route?.params?.endDate ? console.log('exists') : console.log('does not exist');

	const updateJob = (jobIndex, prop, value) => {
		let jobsNew = [...jobs];
		jobsNew[jobIndex][prop] = value;
		setJobs(jobsNew);
		updateJobDebounce(jobsNew[jobIndex]);
	};

	const [updateJobDebounce] = useDebouncedCallback((job) => {
		JobsService.updateJob(props.auth.organization.organization_id, job.job_id, job);
	}, 500);

	const [getJobsDebounce] = useDebouncedCallback(() => {
		const getJobs = async () => {
			let jobsData = await JobsService.getJobs(
				props.auth.organization.organization_id,
				search,
				'DESC',
				'job_timestamp',
				startDate ? moment(startDate).format('YYYY-MM-DD') : null,
				endDate ? moment(endDate).format('YYYY-MM-DD') : null,
				perPage,
				currentPage
			);

			setJobs(jobsData.jobs);
			setJobCount(jobsData.jobCount);
			setPaginationRange(jobsData.paginationRange);
		};

		getJobs();
	}, 500);

	const warnDeleteCustomers = (customer) => {
		console.log('customer: ', customer);
	};

	const columns = [
		...(
			organization.organization_type_id == ORGANIZATION_TYPE_IDS.FLUID || organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER ?
				[
					{
						key: 'organization_title',
						exportName: 'organization_title',
						label: 'Organization',
						type: 'text',
						disabled: true,
						valueExtractor: job => job.organization_title,
						onChangeText() {
						},
					}
				]
				: []
		),
		{
			key: 'job_customer_name',
			exportName: 'job_customer_name',
			label: 'Customer',
			type: 'text',
			valueExtractor: job => job.job_customer_name,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_customer_name', value);
			},
		},
		{
			key: 'job_customer_email',
			exportName: 'job_customer_email',
			label: 'Customer Email',
			type: 'text',
			valueExtractor: job => job.job_customer_email,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_customer_email', value);
			},
		},
		{
			key: 'job_customer_phone',
			exportName: 'job_customer_phone',
			label: 'Customer Phone',
			type: 'text',
			valueExtractor: job => job.job_customer_phone,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_customer_phone', value);
			},
		},
		{
			key: 'job_customer_address',
			exportName: 'job_customer_address',
			label: 'Customer Address',
			type: 'text',
			valueExtractor: job => job.job_customer_address,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_customer_address', value);
			},
		},
		{
			key: 'job_number',
			exportName: 'job_number',
			label: 'Job ID',
			type: 'text',
			valueExtractor: job => job.job_number,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_number', value);
			},
		},
		{
			key: 'team_member',
			exportName: 'user_display_name',
			label: 'Team Member',
			type: 'picker',
			labelExtractor: job => job.user_display_name,
			pickerValueExtractor: user => user.user_id,
			valueExtractor: job => {
				return job.user_id;
			},
			onValueChange(jobIndex, value) {
				updateJob(jobIndex, 'user_id', value);
			},
			items: users,
		},
		{
			key: 'job_product_type',
			exportName: 'job_product_type',
			label: 'Product Type',
			type: 'text',
			valueExtractor: job => job.job_product_type,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_product_type', value);
			},
		},
		{
			key: 'job_product_brand',
			exportName: 'job_product_brand',
			label: 'Product Brand',
			type: 'text',
			valueExtractor: job => job.job_product_brand,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_product_brand', value);
			},
		},
		{
			key: 'job_product_model_number',
			exportName: 'job_product_model_number',
			label: 'Product Model Number',
			type: 'text',
			valueExtractor: job => job.job_product_model_number,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_product_model_number', value);
			},
		},
		{
			key: 'job_timestamp',
			exportName: 'job_timestamp',
			label: 'Job Created Date',
			type: 'text',
			disabled: true,
			valueExtractor: job => formatDateAmPm(job.job_timestamp),
			onChangeText(jobIndex, value) {
			},
		},
		{
			key: 'user_email',
			exportName: 'user_email',
			label: 'Team Member Email',
			type: 'text',
			disabled: true,
			valueExtractor: job => job.user_email,
			onChangeText(jobIndex, value) {
			},
		},
		{
			key: 'store_front_title',
			exportName: 'store_front_title',
			label: 'Group',
			type: 'text',
			disabled: true,
			valueExtractor: job => job.store_front_title,
			onChangeText(jobIndex, value) {
			},
		},
		{
			key: 'job_part_numbers',
			exportName: 'job_part_numbers',
			label: 'Part Numbers',
			type: 'text',
			disabled: true,
			valueExtractor: job => (job.jobParts?.map(part => {return part.job_part_number}).join(', ')),
			onChangeText(jobIndex, value) {
			},
		},
		{
			key: 'job_customer_notes',
			exportName: 'job_customer_notes',
			label: 'Customer Notes',
			type: 'text',
			valueExtractor: job => job.job_customer_notes,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_customer_notes', value);
			},
		},
		{
			key: 'job_notes',
			exportName: 'job_notes',
			label: 'Job Notes',
			type: 'text',
			valueExtractor: job => job.job_notes,
			onChangeText(jobIndex, value) {
				updateJob(jobIndex, 'job_notes', value);
			},
		},
		{
			key: 'review_sent',
			exportName: 'review_sent',
			label: 'Review Sent',
			type: 'text',
			disabled: true,
			valueExtractor: job => (
				job.surveyResponse?.survey_response_email_delivered_timestamp ||
				job.surveyResponse?.survey_response_text_delivered_timestamp ||
				job.surveyResponse?.survey_response_responded_timestamp
					? 'Yes' : 'No'),
			onChangeText(jobIndex, value) {
			},
		},
		{
			key: 'review_completed',
			exportName: 'review_completed',
			label: 'Review Completed',
			type: 'text',
			disabled: true,
			valueExtractor: job => (
				job.surveyResponse?.survey_response_responded_timestamp ? formatDateAmPm(job.surveyResponse?.survey_response_responded_timestamp) : ''),
			onChangeText(jobIndex, value) {
			},
		},
		{
			exportable: false,
			key: 'link',
			label: 'More',
			type: 'custom',
			valueExtractor() {
			},
			render(job) {
				return (
					<LinkButton
						action={() => CommonActions.navigate('Jobs', {
							screen: 'JobIndexEditNavigation',
							params: {
								screen: 'JobEdit',
								params: {
									id: job.job_id,
								},
							},
						})}
						style={[
							{
								backgroundColor: '#0065DB',
								padding: 10,
								borderRadius: 5,
							},
							basicStyles.flexRow,
							basicStyles.flexCenterContent,
						]}
					>
						<AppText style={{
							color: '#FFFFFF',
							marginRight: 10,
						}}>
							Job Page
						</AppText>
						<ChevronHorizontalBlackSvg fill="#FFFFFF" direction="right"/>
					</LinkButton>
				);
			},
		},
	];

	useEffect(() => {
		getJobsDebounce();
	}, [props.auth.organization.organization_id, search, startDate, endDate, currentPage]);

	useEffect(() => {
		const getUsers = async () => {
			let usersData = await UsersService.getUsers(props.auth.organization.organization_id);
			setUsers(usersData);
		};
		getUsers();
	}, [props.auth.organization.organization_id]);

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			<ReportsSearchSection
				onChangeSearch={(value) => {
					navigate('Customers', {
						...props.route?.params,
						currentPage: 1,
						search: value
					});
				}}
				searchValue={search}
				onChangeStartDate={(value) => {
					navigate('Customers', {
						...props.route?.params,
						startDate: moment(value).format('MM-DD-YYYY'),
						currentPage: 1
					})
				}}
				startDateValue={startDate}
				onChangeEndDate={(value) => {
					navigate('Customers', {
						...props.route?.params,
						endDate: moment(value).format('MM-DD-YYYY'),
						currentPage: 1
					})
				}}
				endDateValue={endDate}
				onChangeDates={(dates) => {
					const [start, end] = dates;
					navigate('Customers', {
						...props.route?.params,
						startDate: moment(start).format('MM-DD-YYYY'),
						endDate: moment(end).format('MM-DD-YYYY'),
						currentPage: 1
					})
				}}
			/>
			<PageContentContainer>
				<ContentSection style={[
					basicStyles.spreadSheetWrapper,
					basicStyles.flexScale,
				]}>
					{
						jobCount && users.length ?
							<SpreadSheet
								rowCount={jobCount}
								rows={jobs}
								columns={columns}
								rowKeyExtractor="job_id"
								deleteAction={warnDeleteCustomers}
								exportUrl={API_ROOT + '/organizations/' + props.auth.organization.organization_id + '/jobs?search=' + encodeURIComponent(search) + '&postByStartDate=' + encodeURIComponent(startDate ? moment(startDate).format('YYYY-MM-DD') : '') + '&postByEndDate=' + encodeURIComponent(endDate ? moment(endDate).format('YYYY-MM-DD') : '') + '&perPage=' + perPage + '&page=' + currentPage + '&order=DESC&orderBy=job_timestamp'}
								exportTitle="customers.csv"
								initialDisabledColumns={['job_timestamp', 'user_email', 'store_front_title', 'job_part_numbers', 'job_customer_notes', 'job_notes', 'review_sent', 'review_completed']}
							/>
							:  <View style={basicStyles.flexCenterContent}><AppText>No Records Found</AppText></View>
					}
				</ContentSection>
			</PageContentContainer>
			<Pagination screenName="Customers" pageCount={Math.ceil(jobCount / perPage)} pageIndex={currentPage} paginationRange={paginationRange} />
		</ScrollView>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(Customers);
