import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {ScrollView, View} from 'react-native';
import {CommonActions} from '@react-navigation/native';
import {useDebouncedCallback} from 'use-debounce';

import UsersService from '../../services/UsersService';
import {AVATAR_PATH_USER, AVATAR_DEFAULT, AVATAR_PATH_ORG} from '../../config';
import {capitalizeString, formatDate, getUserDisplayName} from '../../utilities/methods';
import PageTop from '../../components/PageTop';
import PageContentContainer from '../../components/PageContentContainer';
import ActionRowWithAvatar from '../../components/ActionRowWithAvatar';
import Avatar from '../../components/Avatar';
import {textStyles} from '../../styles/text';
import AppText from '../../components/AppText';
import {basicStyles} from '../../styles/basic';
import OrganizationsService from '../../services/OrganizationsService';
import SearchBar from '../../components/SearchBar';
import Pagination from "../../components/Pagination";
import EStyleSheet from 'react-native-extended-stylesheet';

function TeamMembersList(props) {
	const [state, setState] = useState({
		users: [],
		organization: {...props.auth.organization},
		initialized: false,
		pageCount: 1,
		paginationRange: [1],
		activeUsersCount: ''
	});
	const {navigate} = props.navigation;
	const perPage = 10;

	let currentPage = props.route?.params?.currentPage ? parseInt(props.route.params.currentPage) : 1;
	let search = props.route?.params?.search ? props.route.params.search : '';

	const [getDataDebounce] = useDebouncedCallback(() => {
		const getData = async () => {
			let orgData;
			if (props.route?.params?.organizationId) {
				orgData = await OrganizationsService.getOrganization(props.route?.params?.organizationId);
			}
			const usersData = await UsersService.getUsersPaginated(
				props.route?.params?.organizationId || props.auth.organization.organization_id,
				search,
				null,
				null,
				null,
				null,
				perPage,
				currentPage
			);

			currentPage = usersData.currentPage;

			setState(prevState => {
				return Object.assign({}, prevState, {
					users: usersData.users,
					initialized: true,
					...(orgData ? {organization: orgData} : {}),
					pageCount: usersData.pageCount,
					paginationRange: usersData.paginationRange,
					activeUsersCount: usersData.activeUsersCount
				});
			});

		};

		getData();
	}, 500);

	useEffect(() => {
		getDataDebounce();
	}, [props.auth.organization.organization_id, props.route?.params?.organizationId, search, currentPage]);

	return (
		<ScrollView contentContainerStyle={basicStyles.flexScale}>
			{
				state.initialized ?
					<>
						<PageTop>
							<View style={styles.pageHeader}>
								<AppText style={textStyles.pageTitle}>
									<AppText style={[textStyles.semiBold, {marginRight: 10}]}>
										{state.organization.organization_title}
									</AppText>
									<AppText style={textStyles.light}>
									Team
									</AppText>
								</AppText>
							<SearchBar
								placeholder="Search"
								onChangeText={(value) => {
									navigate('TeamMembersList', {
										...props.route?.params,
										currentPage: 1,
										search: value
									});
								}}
								value={search}
								style={styles.searchBar}
							/>
							</View>
							<View style={[
								styles.countWrapper
							]}>
								<AppText style={styles.countLabel}>
									Total Active Users
								</AppText>
								<AppText stles={styles.countValue}>
									{state?.activeUsersCount}
								</AppText>
							</View>
						</PageTop>
						<PageContentContainer>
							{
								state.users.filter(user => user.organization_id == state.organization.organization_id) //todo: temporary to hide non organization users from list. should handle in backend
								      .map(user => {
									      return (
										      <ActionRowWithAvatar
											      key={user.user_id}
											      action={CommonActions.navigate('TeamMemberEdit', {
												      id: user.user_id,
											      })}
											      icon={
												      <Avatar
													      source={{uri: (user.user_avatar_file ? AVATAR_PATH_USER + user.user_avatar_file : (user.organization_default_survey_image ? AVATAR_PATH_ORG + user.organization_default_survey_image : AVATAR_DEFAULT))}}
													      width={66}
												      />
											      }
											      title={getUserDisplayName(user)}
											      slot1={{
												      label: '',
												      value: user.store_front_title,
											      }}
											      slot2={{
												      label: 'Role',
												      value: user.role_title,
											      }}
											      slot3={{
												      label: 'Account Status',
												      value: capitalizeString(user.user_organization_status),
											      }}
											      slot4={{
												      label: 'Average Rating',
												      value: user.survey_star_average,
											      }}
										      />
									      );
								      })
							}
						</PageContentContainer>
					</>
					: null
			}
			<Pagination screenName="TeamMembersList" pageCount={state.pageCount} pageIndex={currentPage} paginationRange={state.paginationRange} />
		</ScrollView>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(TeamMembersList);

const styles = EStyleSheet.create({
	pageHeader: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	searchBar: {
		flex: 1,
		marginRight: 20,
		marginLeft: 20,
	},
	'@media (max-width: 650)': {
		pageHeader: {
			flexDirection: 'column',
			alignItems: 'flex-start'
		},
		searchBar: {
			marginLeft: 0,
			marginTop: 20,
			paddingTop: 5,
			paddingBottom: 5,
			width: '100%'
		}
	},
	countWrapper: {
		marginTop: 10,
		flexDirection: 'row',
		alignItems: 'center',
		display: 'flex'
	},
	countLabel: {
		color: '#9BA8CA',
		fontSize: 16,
		marginRight: 10,
		whiteSpace: 'nowrap'
	},
	countValue: {
		fontSize: 16,
		whiteSpace: 'normal'
	}
});
