import React, {useState, useEffect, useRef} from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {CommonActions} from '@react-navigation/native';
import {useDebouncedCallback} from 'use-debounce';
import {bindActionCreators} from 'redux';
import AsyncStorage from '@react-native-community/async-storage';

import AppText from '../../components/AppText';
import PageTop from '../../components/PageTop';
import PageContentContainer from '../../components/PageContentContainer';
import UsersService from '../../services/UsersService';
import StoreFrontsService from '../../services/StoreFrontsService';
import UploadOverlayHidden from '../../components/UploadOverlayHidden';
import {CLIENT_ACCOUNT_OWNER_ROLE_ID, PARTNER_ACCOUNT_OWNER_ROLE_ID, AVATAR_DEFAULT, AVATAR_PATH_USER, ORGANIZATION_TYPE_IDS, AVATAR_PATH_ORG} from '../../config';
import HourGlassSvg from '../../components/svgs/HourGlassSvg';
import ConfirmSvg from '../../components/svgs/ConfirmSvg';
import ViewSvg from '../../components/svgs/ViewSvg';
import SvgBorder from '../../components/SvgBorder';
import AppButton from '../../components/AppButton';
import ContentSection from '../../components/ContentSection';
import {textStyles} from '../../styles/text';
import {basicStyles} from '../../styles/basic';
import Avatar from '../../components/Avatar';
import ContentSectionDivider from '../../components/ContentSectionDivider';
import FieldSet from '../../components/FieldSet';
import BackBar from '../../components/BackBar';
import {setWarning} from '../../store/warning/warningActions';
import OrganizationsService from '../../services/OrganizationsService';
import RolesService from '../../services/RolesService';
import {checkIfFluidUser, getUserDisplayName} from '../../utilities/methods';
import EStyleSheet from 'react-native-extended-stylesheet';
import AuthService from "../../services/AuthService";
import {emulateUser} from "../../store/auth/authActions";
import UserNotifications from '../../components/UserNotifications';

function TeamMemberSingle(props) {
    const [state, setState] = useState({
        user: {},
        organization: {...props.auth.organization},
        roles: [],
        updatingUser: false
    });
    const [storeFronts, setStoreFronts] = useState([]);
    const [fieldSetChunkSize, setFieldSetChunkSize] = useState(4);
    const [avatarWidth, setAvatarWidth] = useState(169);
    const {setWarning, emulateUser} = props;
    const {navigate} = props.navigation;
    const firstUpdate = useRef(true);

    const organizationId = props.route?.params?.organizationId || props.auth.organization.organization_id;

    const updateUser = (prop, value) => {
        setState(prevState => {
            return Object.assign({}, prevState, {user: {...prevState.user, [prop]: value}});
        });
    };

    const createNewUser = async () => {
        const userData = await UsersService.insertUser(organizationId, state.user);
        navigate('TeamMemberIndexEditNavigation', {
            screen: 'TeamMemberEdit',
            params: {
                id: userData.user_id,
            },
        });
    };

    const [updateUserDebounce] = useDebouncedCallback(() => {
        if (props.route?.params?.id) {
            let userData = UsersService.updateUser(
                organizationId,
                props.route.params.id,
                state.user,
            );
        }
    }, 500);


    const updateAvatar = async (results) => {
        let file = results[0];
        let formData = new FormData();
        formData.append('image', file);

        let fileName;
        try {
            fileName = await UsersService.uploadImage(organizationId, formData);
        } catch (error) {
            console.log('uploadError: ', error);
        }

        updateUser('user_avatar_file', fileName);
    };

    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;
        if (width > 920) {
            setFieldSetChunkSize(4);
            setAvatarWidth(169);
        } else if (width > 500) {
            setFieldSetChunkSize(2);
            setAvatarWidth(120);
        } else {
            setFieldSetChunkSize(1);
            setAvatarWidth(90);
        }
    };
    const emulateCurrentUser = async () => {
        const userData = await AuthService.emulateUser(state.user.user_id, organizationId);
        emulateUser({
            token: userData.token,
            user: userData.user,
            organization: userData.organization,
            organizationRoleId: userData.organization.role_id,
            permissions: userData.permissions
        });

        await AsyncStorage.setItem('emulateAuthToken', userData.token);
        await AsyncStorage.setItem('emulateOrganizationId', userData.organization.organization_id);
        await AsyncStorage.setItem('emulateOrganizationRoleId', userData.organization.role_id);

        if (userData.organization.organization_type_id == ORGANIZATION_TYPE_IDS.CLIENT) {
            navigate('Jobs');
        } else {
            navigate('Clients');
        }
    };

    const sendInvitation = async () => {
        let userData = await UsersService.sendUserInvite(organizationId, props.route.params.id);

        setState(prevState => {
            return Object.assign({}, prevState, {user: userData});
        });
    };

    const updateNotification = async (notificationId, frequency) => {
        updateUser(notificationId, frequency);
    };

    useEffect(() => {
        let userId;
        if (props.route?.params?.id) {
            userId = props.route.params.id;
        }

        const getData = async () => {
            let orgData;
            let rolesData;
            if (props.route?.params?.organizationId) {
                orgData = await OrganizationsService.getOrganization(organizationId);

            }

            let userData;
            if (userId) {
                userData = await UsersService.getUser(organizationId, props.route.params.id);
            }

            rolesData = await RolesService.getOrganizationRoles(organizationId);

            setState(prevState => {
                return Object.assign({}, prevState, {
                        roles: rolesData,
                        ...(userId ? {user: userData} : {}),
                        ...(orgData ? {organization: orgData} : {}),
                        updatingUser: true
                    },
                );
            });
        };

        const getStoreFronts = async () => {
            let storeFrontsData = await StoreFrontsService.getStoreFronts(organizationId);

            setStoreFronts(storeFrontsData);
        };

        getData();
        getStoreFronts();

    }, [organizationId, props.route?.params?.id, props.route]);

    useEffect(() => {
        if (firstUpdate.current) {
            if (Object.keys(state.user).length) {
                firstUpdate.current = false;
            }
            return;
        }

        if (state.updatingUser) {
            setState(prevState => {
                return {...prevState, updatingUser: false}
            });
            return;
        }

        updateUserDebounce();

    }, [state.user, updateUserDebounce, state.initialized, state]);

    return (
        <View style={basicStyles.flexScale} onLayout={handleLayout}>
            <ScrollView contentContainerStyle={basicStyles.flexScale}>
                {
                    state.user.user_id ?
                        <PageTop>
                            <AppText style={textStyles.pageTitle}>
                                <AppText style={textStyles.semiBold}>
                                    {getUserDisplayName(state.user)}
                                </AppText> <AppText style={textStyles.light}>
                                {state.user.role_title}
                            </AppText>
                            </AppText>
                        </PageTop>
                        : null
                }
                <PageContentContainer>
                    {
                        state.user.user_id ?

                            <ContentSection style={[
                                basicStyles.flexRow,
                                styles.contentSection,
                            ]}>
                                <View style={[
                                    basicStyles.padding20,
                                    basicStyles.widthThird,
                                    basicStyles.flexScale,
                                    basicStyles.flexRow,
                                    styles.contentSectionItem,
                                    styles.avatarContainer,
                                ]}>
                                    <View>
                                        <Avatar
                                            source={{uri: (state.user.user_avatar_file ? AVATAR_PATH_USER + state.user.user_avatar_file : (state.user.organization_default_survey_image ? AVATAR_PATH_ORG + state.user.organization_default_survey_image : AVATAR_DEFAULT))}}
                                            width={avatarWidth}
                                        />
                                    </View>
                                    <View style={[
                                        basicStyles.flexScale,
                                        basicStyles.flexCenterContent,
                                        styles.avatarButtons,
                                    ]}>
                                        <AppButton
                                            style={styles.changePhotoButton}
                                            theme="transBlue"
                                            label={state.user.user_avatar_file ? 'Change Photo' : 'Upload Photo'}
                                        >
                                            <UploadOverlayHidden callback={updateAvatar}/>
                                        </AppButton>
                                        {
                                            state.user.user_avatar_file &&
                                            <AppButton
                                                style={styles.removePhotoButton}
                                                theme="transRed"
                                                action={() => {
                                                    setWarning({
                                                        confirmAction: () => updateUser('user_avatar_file', null),
                                                        confirmLabel: 'Remove Photo',
                                                        rejectLabel: 'Cancel',
                                                        warningMessage: 'Are you sure you want to remove the User Photo?',
                                                        active: true,
                                                    });
                                                }}
                                                label="Remove Photo"
                                            />
                                        }
                                    </View>
                                </View>
                                <ContentSectionDivider/>
                                <View style={[
                                    basicStyles.padding20,
                                    basicStyles.widthThird,
                                    basicStyles.flexCenterContent,
                                    styles.contentSectionItem,
                                    styles.accountStatusContainer
                                ]}>
                                    {
                                        state.user.user_organization_accepted_timestamp ?
                                            <>
                                                <SvgBorder>
                                                    <ConfirmSvg/>
                                                </SvgBorder>
                                                <AppText style={styles.accountStatus}>
                                                    Account Confirmed
                                                </AppText>
                                                <AppText style={styles.accountStatusDays}>
                                                    {state.user.user_organization_days_since_accepted} Days Ago
                                                </AppText>
                                            </>
                                            :
                                            (
                                                state.user.user_organization_invitation_sent == 0 ?

                                                    (<>
                                                        <SvgBorder>
                                                            <HourGlassSvg/>
                                                        </SvgBorder>
                                                        <AppText style={styles.accountStatus}>
                                                            Invitation Not Sent
                                                        </AppText>
                                                        <AppText style={styles.accountStatusDays}>
                                                            Added {state.user.user_organization_days_since_invite} Days Ago
                                                        </AppText>
                                                        {props.auth.permissions.includes('manage_account_users') ?
                                                            <AppButton
                                                                style={{marginTop: 10}}
                                                                label="Send Invitation"
                                                                action={() => sendInvitation()}
                                                            />
                                                            : null
                                                        }
                                                    </>)
                                                    :
                                                    (<>
                                                        <SvgBorder>
                                                            <HourGlassSvg/>
                                                        </SvgBorder>
                                                        <AppText style={styles.accountStatus}>
                                                            Account Pending
                                                        </AppText>
                                                        <AppText style={styles.accountStatusDays}>
                                                            {state.user.user_organization_days_since_invite} Days Ago
                                                        </AppText>
                                                    </>)
                                            )
                                    }
                                </View>
                                <ContentSectionDivider/>
                                <View style={[
                                    basicStyles.padding20,
                                    basicStyles.widthThird,
                                    basicStyles.flexCenterContent,
                                    styles.contentSectionItem,
                                    styles.emulateUserContainer
                                ]}>
                                    <SvgBorder>
                                        <ViewSvg/>
                                    </SvgBorder>
                                    <AppText style={styles.emulateUserLabel}>
                                        Emulate User
                                    </AppText>
                                    <AppButton
                                        label="Assume Role"
                                        action={() => emulateCurrentUser()}
                                    />
                                </View>
                            </ContentSection>
                            : null
                    }
                    <AppText style={[
                        textStyles.pageSubTitle,
                        textStyles.semiBold,
                    ]}>
                        Account Details
                    </AppText>
                    <FieldSet
                        style={{marginBottom: 50}}
                        chunkSize={fieldSetChunkSize}
                        fields={[
                            {
                                type: 'picker',
                                label: 'Group',
                                onValueChange: value => updateUser('store_front_id', value),
                                selectedValue: state.user.store_front_id || '',
                                items: storeFronts,
                                labelExtractor: 'store_front_title',
                                valueExtractor: 'store_front_id',

                            },
                            {
                                type: 'text',
                                label: 'First Name',
                                onChangeText: value => updateUser('profile_first_name', value),
                                value: state.user.profile_first_name || '',

                            },
                            {
                                type: 'text',
                                label: 'Display Name',
                                onChangeText: value => updateUser('profile_display_name', value),
                                value: state.user.profile_display_name || '',

                            },
                            {
                                type: 'text',
                                label: 'Last Name',
                                onChangeText: value => updateUser('profile_last_name', value),
                                value: state.user.profile_last_name || '',

                            },
                            {
                                type: 'text',
                                label: 'Email',
                                onChangeText: value => updateUser('user_email', value),
                                value: state.user.user_email || '',

                            },
                            {
                                type: 'picker',
                                label: 'Employee Status',
                                onValueChange: value => updateUser('user_organization_status', value),
                                selectedValue: state.user.user_organization_status || '',
                                items: [
                                    {
                                        label: 'Active',
                                        value: 'active',
                                    },
                                    {
                                        label: 'Inactive',
                                        value: 'inactive',
                                    },
                                ],
                                labelExtractor: 'label',
                                valueExtractor: 'value',
                                disabled: (state.user.role_id == CLIENT_ACCOUNT_OWNER_ROLE_ID || state.user.role_id == PARTNER_ACCOUNT_OWNER_ROLE_ID)  // Don't allow account owner to switch status
                                    || !props.auth.permissions.includes('archive_users')
                            },
                            {
                                type: 'picker',
                                label: 'Role',
                                onValueChange: value => updateUser('role_id', value),
                                selectedValue: state.user.role_id || '',
                                items: state.roles.filter(role => {
                                    //Don't show Account Owner role to non account owners
                                    if (
                                        (state.user.role_id != CLIENT_ACCOUNT_OWNER_ROLE_ID && role.role_id == CLIENT_ACCOUNT_OWNER_ROLE_ID) ||
                                        (state.user.role_id != PARTNER_ACCOUNT_OWNER_ROLE_ID && role.role_id == PARTNER_ACCOUNT_OWNER_ROLE_ID)
                                    ) {
                                        return false;
                                    }

                                    return true;
                                }),
                                labelExtractor: 'role_title',
                                valueExtractor: 'role_id',
                                disabled: (state.user.role_id == CLIENT_ACCOUNT_OWNER_ROLE_ID || state.user.role_id == PARTNER_ACCOUNT_OWNER_ROLE_ID)  // Don't allow account owner to switch role
                                    || !props.auth.permissions.includes('update_user_roles')
                            },
                            {
                                type: 'picker',
                                label: 'Visible in plugin?',
                                onValueChange: value => updateUser('user_plugin_visibility', value),
                                selectedValue: state.user.user_plugin_visibility || '1',
                                items: [
                                    {
                                        label: 'Visible',
                                        value: '1'
                                    },
                                    {
                                        label: 'Not Visible',
                                        value: '0'
                                    },
                                ]
                            },
                            ...(
                                !state.user.user_id ?
                                    [{
                                        type: 'picker',
                                        label: 'Send Invitation?',
                                        onValueChange: value => updateUser('send_invitation', value),
                                        selectedValue: state.user.send_invitation || '',
                                        items: [
                                            {
                                                label: 'No',
                                                value: '0',
                                            },
                                            {
                                                label: 'Yes',
                                                value: '1',
                                            },
                                        ],
                                        labelExtractor: 'label',
                                        valueExtractor: 'value',
                                    }]
                                    : []
                            )
                        ]}
                    />

                    {(((props.auth.permissions.includes('view_account_reports') || props.auth.permissions.includes('admin_team_notifications') || props.auth.permissions.includes('admin_notification_failures')) && props.auth.permissions.includes('manage_account_users')) || checkIfFluidUser()) &&
                    <>
                        <AppText style={[
                            textStyles.pageSubTitle,
                            textStyles.semiBold,
                        ]}>
                            Alerts & Notifications
                        </AppText>
                        <UserNotifications
                            user={state.user}
                            updateNotification={updateNotification}
                            hasViewAccountReportsPermission={props.auth.permissions.includes('view_account_reports') || checkIfFluidUser()}
                            hasAdminTeamNotificationsPermission={props.auth.permissions.includes('admin_team_notifications') || checkIfFluidUser()}
                            hasAdminNotificationFailuresPermission={props.auth.permissions.includes('admin_notification_failures') || checkIfFluidUser()}
                        />
                    </>
                    }

                    {
                        !state.user.user_id ?
                            <AppButton
                                label="Invite User"
                                action={() => createNewUser()}
                                style={{width: 145, alignSelf: 'center', marginTop: 50, height: 50}}
                            />
                            : null
                    }
                </PageContentContainer>
            </ScrollView>
        </View>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        setWarning,
        emulateUser
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberSingle);

const styles = EStyleSheet.create({
    accountStatus: {
        fontFamily: 'SourceSansPro-SemiBoldItalic',
        fontSize: 16,
        marginTop: 10,
        marginBottom: 5,
    },
    accountStatusDays: {
        fontSize: 12,
        color: '#9BA8CA',
    },
    emulateUserLabel: {
        fontSize: 20,
        marginTop: 5,
        marginBottom: 10,
    },
    changePhotoButton: {
        marginBottom: 10,
        width: 125
    },
    removePhotoButton: {
        width: 125
    },
    contentSection: {
        marginBottom: 50,
    },
    '@media (max-width: 1200)': {
        avatarContainer: {
            flexDirection: 'column',
            alignItems: 'center'
        },
        avatarButtons: {
            paddingTop: 20
        },
        changePhotoButton: {
            marginBottom: 10
        },
    },
    '@media (max-width: 640)': {
        contentSection: {
            flexDirection: 'column',
            alignItems: 'center'
        },
        contentSectionItem: {
            width: '100%',
            borderBottomWidth: 1,
            borderBottomColor: '#E2E9F4',
        },
        avatarContainer: {},
        accountStatusContainer: {},
        emulateUserContainer: {
            borderBottomWidth: 0
        }
    }
});
