import React from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import StoreFrontsList from './StoreFrontsList';
import StoreFrontSingle from './StoreFrontSingle';
import {basicStyles} from '../../styles/basic';

const Stack = createStackNavigator();

function StoreFrontIndexEditNavigation(props) {
	const organizationId = props.route?.params?.organizationId;

	return (
		<View style={basicStyles.flexScale}>
			<Stack.Navigator
				backBehavior="history"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: '#F8FAFD'
					}
				}}
				initialRouteName="StoreFrontsList"
			>
				<Stack.Screen
					name={"StoreFrontsList"}
					component={StoreFrontsList}
					initialParams={{organizationId}}
					options={{
						title: 'Groups'
					}}
				/>
				<Stack.Screen
					name={"StoreFrontEdit"}
					component={StoreFrontSingle}
					initialParams={{organizationId}}
					options={{
						title: 'Groups'
					}}
				/>
				<Stack.Screen
					name={"StoreFrontAdd"}
					component={StoreFrontSingle}
					initialParams={{organizationId}}
					options={{
						title: 'Groups'
					}}
				/>
			</Stack.Navigator>
		</View>
	);
}

export default StoreFrontIndexEditNavigation;
