import React, {useState, useEffect} from 'react';
import {View, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {useDebouncedCallback} from 'use-debounce';
import {CommonActions} from '@react-navigation/native';
import {bindActionCreators} from 'redux';

import {textStyles} from '../../styles/text';
import StoreFrontsService from '../../services/StoreFrontsService';
import PageTop from '../../components/PageTop';
import AppText from '../../components/AppText';
import {AVATAR_DEFAULT, AVATAR_PATH_STORE_FRONT, AVATAR_PATH_SURVEY_WEBSITE} from '../../config';
import PageContentContainer from '../../components/PageContentContainer';
import ContentSection from '../../components/ContentSection';
import Avatar from '../../components/Avatar';
import {basicStyles} from '../../styles/basic';
import ContentSectionDivider from '../../components/ContentSectionDivider';
import UploadOverlayHidden from '../../components/UploadOverlayHidden';
import TimeZonesService from '../../services/TimeZonesService';
import FieldSet from '../../components/FieldSet';
import LocationsService from '../../services/LocationsService';
import BackBar from '../../components/BackBar';
import AppButton from '../../components/AppButton';
import {setWarning} from '../../store/warning/warningActions';
import OrganizationsService from '../../services/OrganizationsService';
import SurveyWebsitesService from "../../services/SurveyWebsitesService";
import EStyleSheet from "react-native-extended-stylesheet";
import SurveysService from "../../services/SurveysService";

function StoreFrontSingle(props) {
    const [state, setState] = useState({
        storeFront: {
            surveyWebsites: []
        },
        organization: {surveyWebsites: []},
        initialized: false,
    });
    const [survey, setSurvey] = useState({surveyQuestions: []});
    const [isMobile, setIsMobile] = useState(false);
    const [surveyWebsites, setSurveyWebsites] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [contactInformationChunkSize, setContactInformationChunkSize] = useState(4);
    const [isTablet, setIsTablet] = useState(false);
    const {setWarning} = props;
    const organizationId = props.route?.params?.organizationId;

    const updateStoreFront = (prop, value) => {
        setState(prevState => {
            return Object.assign({}, prevState, {storeFront: {...prevState.storeFront, [prop]: value}});
        });
    };

    const createNewStoreFront = async () => {
        const {navigate} = props.navigation;
        const storeFrontData = await StoreFrontsService.insertStoreFront(organizationId, state.storeFront);
        navigate('StoreFrontEdit', {
            id: storeFrontData.store_front_id,
        });
    };

    const [updateStoreFrontDebounce] = useDebouncedCallback(() => {
        if (props.route?.params?.id) {
            StoreFrontsService.updateStoreFront(
                organizationId,
                props.route?.params?.id,
                {
                    ...state.storeFront,
                    surveyWebsites: state.storeFront.surveyWebsites.filter(
                        surveyWebsite => surveyWebsite.survey_website_id && surveyWebsite.store_front_survey_website_url
                    )
                },
            );
        }
    }, 500);

    const [updateSurveyDebounce] = useDebouncedCallback(() => {
        if (survey.survey_id) {
            SurveysService.updateSurvey(survey.survey_id, survey)
        }
    }, 500);

    const handleFileUpload = async (results) => {
        let file = results[0];
        let formData = new FormData();
        formData.append('image', file);

        let fileName = null;
        try {
            fileName = await StoreFrontsService.uploadImage(organizationId, formData);
        } catch (error) {
            console.log('uploadError: ', error);
        }

        return fileName;
    };

    const updateAvatar = async (results) => {
        const fileName = await handleFileUpload(results);

        updateStoreFront('store_front_thumbnail', fileName);
    };

    const updateDefaultSurveyImage = async (results) => {
        const fileName = await handleFileUpload(results);

        updateStoreFront('store_front_default_survey_image', fileName);
    };

    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;

        if (width > 1200) {
            setContactInformationChunkSize(4);
        } else if (width > 930) {
            setContactInformationChunkSize(3);
        } else if (width > 650) {
            setContactInformationChunkSize(2);
        } else {
            setContactInformationChunkSize(1);
        }

        if (width > 688) {
            setIsMobile(false);
        } else {
            setIsMobile(true);
        }

        if (width > 600) {
            setIsTablet(false);
        } else {
            setIsTablet(true);
        }
    };

    const addSurveyWebsite = () => {
        setState({
            ...state,
            storeFront: {
                ...state.storeFront,
                surveyWebsites: [
                    ...state.storeFront.surveyWebsites,
                    {
                        store_front_id: state.storeFront.store_front_id
                    }
                ]
            }
        });
    };

    const updateSurveyWebsite = (prop, value, index) => {
        let surveyWebsites = [...state.storeFront.surveyWebsites];
        surveyWebsites[index][prop] = value;

        setState({
            ...state,
            storeFront: {
                ...state.storeFront,
                surveyWebsites
            }
        });
    };

    const getRedirectableSurveyQuestion = () => {
        return survey.surveyQuestions[getRedirectableSurveyQuestionIndex()];
    };


    const getRedirectableSurveyQuestionIndex = () => {
        return survey.surveyQuestions.findIndex((surveyQuestion) => {
            return surveyQuestion.survey_question_redirect_enabled == 1;
        });
    };

    const updateSurveyQuestionRedirect = (prop, value, surveyQuestionRedirectIndex) => {
        const redirectableSurveyQuestionIndex = getRedirectableSurveyQuestionIndex();
        setSurvey(prevSurvey => {
            let newSurvey = Object.assign({}, prevSurvey);
            newSurvey.surveyQuestions[redirectableSurveyQuestionIndex].surveyQuestionRedirects.filter(surveyQuestionRedirect => surveyQuestionRedirect.store_front_id == state.storeFront.store_front_id)[surveyQuestionRedirectIndex][prop] = value;

            return newSurvey;
        });
    }

    const addSurveyQuestionRedirect = () => {
        const redirectableSurveyQuestionIndex = getRedirectableSurveyQuestionIndex();

        setSurvey(prevSurvey => {
            let newSurvey = Object.assign({}, prevSurvey);

            newSurvey.surveyQuestions[redirectableSurveyQuestionIndex].surveyQuestionRedirects.push({store_front_id: state.storeFront.store_front_id});

            return newSurvey;
        });
    };


    useEffect(() => {
        let storeFrontId;
        if (props.route?.params?.id) {
            storeFrontId = props.route.params.id;
        }

        const getData = async () => {
            let orgData;
            if (organizationId) {
                orgData = await OrganizationsService.getOrganization(organizationId);
                let surveyData = await OrganizationsService.getOrganizationSurvey(organizationId);
                setSurvey(surveyData);
            }

            let storeFrontData;
            if (storeFrontId) {
                let surveyWebsitesData = await SurveyWebsitesService.getSurveyWebsites();
                setSurveyWebsites(surveyWebsitesData);

                storeFrontData = await StoreFrontsService.getStoreFront(organizationId, storeFrontId);
            }

            setState(prevState => {
                return Object.assign({}, prevState, {
                        ...(storeFrontId ? {storeFront: storeFrontData} : {surveyWebsites: []}),
                        ...(orgData ? {organization: orgData} : {}),
                    },
                );
            });
        };

        getData();
    }, [organizationId, props.route?.params?.id, props.route]);

    useEffect(() => {
        const getData = async () => {
            const timeZonesData = await TimeZonesService.getTimeZones();
            setTimeZones(timeZonesData);
        };
        getData();
    }, []);

    useEffect(() => {
        if (!state.initialized) {
            if (Object.keys(state.storeFront).length > 1 && state.organization.organization_id && surveyWebsites.length) {
                setState({...state, initialized: true});
            }
            return;
        }

        updateStoreFrontDebounce();
    }, [state.storeFront, updateStoreFrontDebounce, state.initialized, state]);

    useEffect(() => {
        if (!state.initialized) {
            return;
        }

        updateSurveyDebounce();
    }, [survey, updateSurveyDebounce]);

    return (
        <View style={{flex: 1}} onLayout={handleLayout}>
            <BackBar
                action={CommonActions.navigate('StoreFrontsList')}
                label="Back to All Groups"
            />
            <ScrollView contentContainerStyle={{flex: 1}}>
                {
                    state.storeFront.store_front_id ?
                        <PageTop style={[
                            basicStyles.flexRow,
                            basicStyles.alignContentCenter,
                            basicStyles.justifyContentSpaceBetween,
                        ]}>
                            <View style={[
                                basicStyles.flexRow,
                                basicStyles.alignContentCenter,
                                basicStyles.justifyContentSpaceBetween,
                            ]}>
                                <Avatar
                                    source={{uri: state.storeFront.store_front_thumbnail ? AVATAR_PATH_STORE_FRONT + state.storeFront.store_front_thumbnail : AVATAR_DEFAULT}}
                                    width={66}
                                    style={{marginRight: 20}}
                                />
                                <AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
                                    {state.storeFront.store_front_title}
                                </AppText>
                            </View>
                        </PageTop>
                        : null
                }
                <PageContentContainer>
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                        Imagery
                    </AppText>
                    <ContentSection
                        style={[
                            {marginBottom: 50},
                            (isTablet ?
                                    {justifyContent: 'center', alignItems: 'center'}
                                    :
                                    {flexDirection: 'row'}
                            ),
                        ]}>
                        <View style={[
                            basicStyles.padding20,
                            basicStyles.flexScale,
                            basicStyles.alignContentCenter,
                            (isTablet ? {width: '100%'} : {width: '50%'}),
                        ]}>
                            <AppText style={[
                                textStyles.semiBold,
                                {marginBottom: 20},
                            ]}>
                                Company Logo
                            </AppText>
                            <Avatar
                                source={{uri: state.storeFront.store_front_thumbnail ? AVATAR_PATH_STORE_FRONT + state.storeFront.store_front_thumbnail : AVATAR_DEFAULT}}
                                width={151}
                                style={{marginBottom: 20}}
                            />
                            <View style={[
                                basicStyles.flexRow,
                                basicStyles.alignContentCenter,
                            ]}>
                                <AppButton
                                    theme="transBlue"
                                    label={state.storeFront.store_front_thumbnail ? 'Change Photo' : 'Upload Photo'}
                                >
                                    <UploadOverlayHidden callback={updateAvatar}/>
                                </AppButton>
                                <AppButton
                                    style={{marginLeft: 10}}
                                    theme="transRed"
                                    action={() => {
                                        setWarning({
                                            confirmAction: () => updateStoreFront('store_front_thumbnail', null),
                                            confirmLabel: 'Remove Photo',
                                            rejectLabel: 'Cancel',
                                            warningMessage: 'Are you sure you want to remove the Group Logo?',
                                            active: true,
                                        });
                                    }}
                                    label="Remove Photo"
                                />
                            </View>
                        </View>
                        <ContentSectionDivider/>
                        <View style={[
                            basicStyles.padding20,
                            basicStyles.flexScale,
                            basicStyles.alignContentCenter,
                            (isTablet ? {width: '100%', borderTopWidth: 1, borderTopColor: '#E2E9F4'} : {width: '50%'}),
                        ]}>
                            <AppText style={[
                                textStyles.semiBold,
                                {marginBottom: 20},
                            ]}>
                                Default Survey Image
                            </AppText>
                            <Avatar
                                source={{uri: state.storeFront.store_front_default_survey_image ? AVATAR_PATH_STORE_FRONT + state.storeFront.store_front_default_survey_image : AVATAR_DEFAULT}}
                                width={151}
                                style={{marginBottom: 20}}
                            />
                            <View style={[
                                basicStyles.flexRow,
                                basicStyles.alignContentCenter,
                            ]}>

                                <AppButton
                                    theme="transBlue"
                                    label={state.storeFront.store_front_default_survey_image ? 'Change Photo' : 'Upload Photo'}
                                >
                                    <UploadOverlayHidden callback={updateDefaultSurveyImage}/>
                                </AppButton>
                                <AppButton
                                    style={{marginLeft: 10}}
                                    theme="transRed"
                                    action={() => {
                                        setWarning({
                                            confirmAction: () => updateStoreFront('store_front_default_survey_image', null),
                                            confirmLabel: 'Remove Photo',
                                            rejectLabel: 'Cancel',
                                            warningMessage: 'Are you sure you want to remove the Group Default Survey Image?',
                                            active: true,
                                        });
                                    }}
                                    label="Remove Photo"
                                />
                            </View>
                        </View>
                    </ContentSection>
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                        Contact Information
                    </AppText>
                    <FieldSet
                        chunkSize={contactInformationChunkSize}
                        style={{marginBottom: 50}}
                        fields={[
                            {
                                type: 'text',
                                label: 'Group Name',
                                onChangeText: value => updateStoreFront('store_front_title', value),
                                value: state.storeFront.store_front_title || '',
                            },
                            {
                                type: 'text',
                                label: 'Phone',
                                onChangeText: value => updateStoreFront('store_front_phone', value),
                                value: state.storeFront.store_front_phone || '',
                            },
                            {
                                type: 'text',
                                label: 'Business Email',
                                onChangeText: value => updateStoreFront('store_front_email', value),
                                value: state.storeFront.store_front_email || '',
                            },
                            {
                                type: 'text',
                                label: 'Website',
                                onChangeText: value => updateStoreFront('store_front_website', value),
                                value: state.storeFront.store_front_website || '',
                            },
                            {
                                type: 'picker',
                                label: 'Time Zone',
                                onValueChange: value => updateStoreFront('time_zone_id', value),
                                selectedValue: state.storeFront.time_zone_id || '',
                                items: timeZones,
                                labelExtractor: 'time_zone_title',
                                valueExtractor: 'time_zone_id',
                            },
                        ]}
                    />
                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                        Location
                    </AppText>
                    <FieldSet
                        chunkSize={1}
                        style={{marginBottom: 50}}
                        fields={[
                            {
                                type: 'address',
                                label: 'Address',
                                onChangeAddress: value => updateStoreFront('store_front_address', value),
                                value: state.storeFront.store_front_address || '',
                            },
                        ]}
                    />
                    {
                        !state.storeFront.store_front_id ?
                            <AppButton
                                label="Create Group"
                                action={() => createNewStoreFront()}
                                style={{width: 145, alignSelf: 'center', marginTop: 50, height: 50}}
                            />
                            : null
                    }
                    {
                        state.storeFront.store_front_id ?
                            <>
                                <View style={[basicStyles.flexRow, basicStyles.alignContentCenter, basicStyles.justifyContentSpaceBetween, styles.surveyWebsitesHeader]}>
                                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
                                        Survey Websites
                                    </AppText>
                                    <AppButton
                                        label="Add Review Website"
                                        action={() => addSurveyWebsite()}
                                        style={styles.surveyWebsitesButton}
                                    />
                                </View>
                                {
                                    state.storeFront.surveyWebsites.map((surveyWebsite, index) => {
                                        let surveyWebsiteObj = surveyWebsites.find((theSurveyWebsite) => {
                                            return theSurveyWebsite.survey_website_id === surveyWebsite.survey_website_id
                                        });
                                        return (
                                            <FieldSet
                                                key={index}
                                                chunkSize={(isMobile ? 1 : 2)}
                                                style={{marginBottom: 10}}
                                                fields={[
                                                    {
                                                        type: 'picker',
                                                        label: 'Survey Website',
                                                        onValueChange: value => updateSurveyWebsite('survey_website_id', value, index),
                                                        selectedValue: surveyWebsite.survey_website_id || '',
                                                        items: surveyWebsites,
                                                        labelExtractor: 'survey_website_title',
                                                        valueExtractor: 'survey_website_id',
                                                        iconExtractor: 'survey_website_icon',
                                                        iconRoot: AVATAR_PATH_SURVEY_WEBSITE,
                                                        showLabel: false
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'URL',
                                                        onChangeText: value => updateSurveyWebsite('store_front_survey_website_url', value, index),
                                                        value: surveyWebsite.store_front_survey_website_url || ''
                                                    },
                                                    ...(
                                                        surveyWebsiteObj?.survey_website_redirect_capable ?
                                                            [
                                                                {
                                                                    type: 'picker',
                                                                    label: 'Auto-Redirect?',
                                                                    onValueChange: value => updateSurveyWebsite('store_front_survey_website_redirect', value, index),
                                                                    selectedValue: surveyWebsite.store_front_survey_website_redirect || '0',
                                                                    items: [
                                                                        {
                                                                            label: 'Yes',
                                                                            value: '1'
                                                                        },
                                                                        {
                                                                            label: 'No',
                                                                            value: '0'
                                                                        },
                                                                    ]
                                                                }
                                                            ]
                                                            :
                                                            []
                                                    )
                                                ]}
                                            />
                                        );
                                    })
                                }
                            </>
                            : null
                    }
                    {
                        getRedirectableSurveyQuestion() && state.storeFront.store_front_id ?
                            <>
                                <View style={[basicStyles.flexRow, basicStyles.alignContentCenter, basicStyles.justifyContentSpaceBetween, styles.surveyWebsitesHeader, {marginTop: 50}]}>
                                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
                                        Overall Rating Redirect
                                    </AppText>
                                    <AppButton
                                        label="Add Rating Redirect"
                                        action={() => addSurveyQuestionRedirect()}
                                        style={styles.surveyWebsitesButton}
                                    />
                                </View>
                                {
                                    getRedirectableSurveyQuestion().surveyQuestionRedirects.filter(surveyQuestionRedirect => surveyQuestionRedirect.store_front_id == state.storeFront.store_front_id).map((surveyQuestionRedirect, index) => {
                                        return (
                                            <FieldSet
                                                style={{marginBottom: 10}}
                                                key={index}
                                                chunkSize={2}
                                                fields={[
                                                    {
                                                        type: 'picker',
                                                        label: 'Overall Rating Value',
                                                        onValueChange: value => updateSurveyQuestionRedirect('survey_question_redirect_value', value, index),
                                                        selectedValue: surveyQuestionRedirect.survey_question_redirect_value || '',
                                                        items: [
                                                            {
                                                                label: 'One',
                                                                value: '1',
                                                            },
                                                            {
                                                                label: 'Two',
                                                                value: '2',
                                                            },
                                                            {
                                                                label: 'Three',
                                                                value: '3',
                                                            },
                                                            {
                                                                label: 'Four',
                                                                value: '4',
                                                            },
                                                            {
                                                                label: 'Five',
                                                                value: '5',
                                                            },
                                                        ].filter((item) => {
                                                            //filter values already selected
                                                            for (let surveyQuestionRedirectObj of getRedirectableSurveyQuestion().surveyQuestionRedirects.filter(surveyQuestionRedirect => surveyQuestionRedirect.store_front_id == state.storeFront.store_front_id)) {
                                                                if (surveyQuestionRedirectObj.survey_question_redirect_value == surveyQuestionRedirect.survey_question_redirect_value) {
                                                                    return true;
                                                                } else if (surveyQuestionRedirectObj.survey_question_redirect_value == item.value) {
                                                                    return false;
                                                                }
                                                            }
                                                            return true;
                                                        })
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'Redirect URL',
                                                        onChangeText: (value) => updateSurveyQuestionRedirect('survey_question_redirect_url', value, index),
                                                        value: surveyQuestionRedirect.survey_question_redirect_url || ''
                                                    }
                                                ]}

                                            />
                                        );
                                    })
                                }
                            </>
                            : null
                    }
                </PageContentContainer>
            </ScrollView>
        </View>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        setWarning,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(StoreFrontSingle);

const styles = EStyleSheet.create({
    surveyWebsitesHeader: {
        flexDirection: 'row',
        marginBottom: 25
    },
    '@media (max-width: 375)': {
        surveyWebsitesHeader: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        },
        surveyWebsitesButton: {
            width: '100%',
            marginTop: 15
        }
    }
});
