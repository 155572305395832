import React, {useEffect, useState, useMemo} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';

import {createSubNavigator} from '../../components/SubNavigator';
import PaymentSettings from './PaymentSettings';
import ManageSubscription from "./ManageSubscription";
import MakePayment from "./MakePayment";
import {basicStyles} from '../../styles/basic';
import OrganizationSingle from "../Organizations/OrganizationSingle";
import OrganizationsService from "../../services/OrganizationsService";
import {ORGANIZATION_TYPE_IDS} from "../../config";
import StoreFrontIndexEditNavigation from "../Organizations/StoreFrontIndexEditNavigation";
import SurveySettings from "../Organizations/SurveySettings";
import Activity from "../Organizations/Activity";
import MessageFlowSettings from "../Organizations/MessageFlowSettings";

const SubNavigator = createSubNavigator();

function AccountSettings(props) {
	const organizationId = props.route.params.organizationId;
	const [organization, setOrganization] = useState({});

	useEffect(() => {
		const getData = async () => {
			if (organizationId) {
				let orgData = await OrganizationsService.getOrganization(organizationId);
				setOrganization(orgData);
			}
		};

		getData();
	}, [organizationId]);

	const adminPermissions = useMemo(() => {
		return organization
			&& (organization.organization_type_id == ORGANIZATION_TYPE_IDS.CLIENT || organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER)
			&& props.auth.permissions.includes('manage_account_store_fronts');
	}, [organization, props.auth.permissions]);

	return (
		<View style={basicStyles.flexScale}>
			<SubNavigator.Navigator  backBehavior="history" initialRouteName="OrganizationEdit">
				<SubNavigator.Screen
					name={'OrganizationEdit'}
					component={OrganizationSingle}
					options={{
						title: 'Company Profile',
					}}
					initialParams={{organizationId}}
				/>
				{
					adminPermissions ?
						<>
							<SubNavigator.Screen
								name={'StoreFrontIndexEditNavigation'}
								component={StoreFrontIndexEditNavigation}
								options={{
									title: 'Groups',
								}}
								initialParams={{organizationId}}
							/>
							<SubNavigator.Screen
								name={'SurveySettings'}
								component={SurveySettings}
								options={{
									title: 'Survey Settings',
								}}
								initialParams={{organizationId}}
							/>
							<SubNavigator.Screen
								name={'Activity'}
								component={Activity}
								options={{
									title: 'Activity',
								}}
								initialParams={{organizationId}}
							/>
						</>
						: null
				}
				<SubNavigator.Screen
					name="PaymentSettings"
					component={PaymentSettings}
					options={{
						title: 'Payment + Billing'
					}}
					initialParams={{organizationId}}
				/>
				<SubNavigator.Screen
					name="ManageSubscription"
					component={ManageSubscription}
					options={{
						title: 'Plans'
					}}
					initialParams={{organizationId}}
				/>
				<SubNavigator.Screen
					name="MakePayment"
					component={MakePayment}
					options={{
						title: 'Make a Payment'
					}}
					initialParams={{organizationId}}
				/>
				{
					adminPermissions && organization.organization_message_flow_active === '1' ?
						<SubNavigator.Screen
							name="MessageFlowSettings"
							component={MessageFlowSettings}
							options={{
								title: 'MessageFlow Settings'
							}}
							initialParams={{organizationId}}
						/>
						: null
				}
			</SubNavigator.Navigator>
		</View>
	);
}

const mapStateToProps = (state) => {
	const {auth} = state;
	return {auth};
};

export default connect(mapStateToProps)(AccountSettings);
