import React from 'react';
import {View} from 'react-native';
import {connect} from "react-redux";

import {createSubNavigator} from '../../components/SubNavigator';
import OrganizationSingle from './OrganizationSingle';
import ClientPartnersTeamStackNavigation from './ClientPartnersTeamStackNavigation';
import SurveySettings from './SurveySettings';
import StoreFrontIndexEditNavigation from './StoreFrontIndexEditNavigation';
import {basicStyles} from '../../styles/basic';
import {FLUID_ADMIN_ROLE_ID, FLUID_ROLE_IDS, ORGANIZATION_TYPE_IDS} from '../../config';
import ManageSubscription from "../AccountSettings/ManageSubscription";
import PaymentSettings from "../AccountSettings/PaymentSettings";
import JobsList from '../Jobs/JobsList';
import JobIndexEditNavigation from '../Jobs/JobIndexEditNavigation';
import CustomUploads from "../Jobs/CustomUploads";

const SubNavigator = createSubNavigator();

function ClientPartnerSingleNavigation(props) {
    let backLink, backLinkLabel;
    const backLinkParams = {
        screen: 'OrganizationList',
    };
    const {organizationTypeId} = props.route.params;

    if (organizationTypeId === ORGANIZATION_TYPE_IDS.CLIENT) {
        backLink = 'Clients';
        backLinkLabel = 'Back to All Clients';
    } else if (organizationTypeId === ORGANIZATION_TYPE_IDS.PARTNER) {
        backLink = 'Partners';
        backLinkLabel = 'Back to All Partners';
    }

    const organizationId = props.route.params.organizationId;

    return (
        <View style={basicStyles.flexScale}>
            <SubNavigator.Navigator
                backBehavior="history"
                initialRouteName={"OrganizationEdit"}
                backLink={props.miscellaneous.isJobSinglePage ? null : backLink}
                backLinkParams={backLinkParams}
                backLinkLabel={backLinkLabel}
            >
                <SubNavigator.Screen
                    name="OrganizationEdit"
                    options={{
                        title: 'Company Profile',
                    }}
                    component={OrganizationSingle}
                    initialParams={{organizationId}}
                />
                <SubNavigator.Screen
                    name="ClientPartnersTeamStackNavigation"
                    options={{
                        title: 'Team',
                    }}
                    component={ClientPartnersTeamStackNavigation}
                    initialParams={{organizationId}}
                />
                {
                    organizationTypeId === ORGANIZATION_TYPE_IDS.CLIENT || organizationId == props.auth.organization.organization_id ?
                        <>
                            <SubNavigator.Screen
                                name="StoreFrontIndexEditNavigation"
                                options={{
                                    title: 'Groups',
                                }}
                                component={StoreFrontIndexEditNavigation}
                                initialParams={{organizationId}}
                            />
                            <SubNavigator.Screen
                                name="SurveySettings"
                                options={{
                                    title: 'Survey Settings',
                                }}
                                component={SurveySettings}
                                initialParams={{organizationId}}
                            />
                            {
                                FLUID_ROLE_IDS.includes(props.auth.organizationRoleId) ?
                                    <>
                                        <SubNavigator.Screen
                                            name="PaymentSettings"
                                            component={PaymentSettings}
                                            options={{
                                                title: 'Payment + Billing'
                                            }}
                                            initialParams={{organizationId}}
                                        />
                                        <SubNavigator.Screen
                                            name="ManageSubscription"
                                            component={ManageSubscription}
                                            options={{
                                                title: 'Plans'
                                            }}
                                            initialParams={{organizationId}}
                                        />
                                        {props.auth.organizationRoleId === FLUID_ADMIN_ROLE_ID && (
                                            <>
                                                <SubNavigator.Screen
                                                    name={"JobIndexEditNavigation"}
                                                    component={JobIndexEditNavigation}
                                                    options={{
                                                        title: 'Jobs'
                                                    }}
                                                    initialParams={{organizationId}}
                                                />
                                                <SubNavigator.Screen
                                                    name="CustomUploads"
                                                    component={CustomUploads}
                                                    options={{
                                                        title: 'Custom Uploads'
                                                    }}
                                                    initialParams={{organizationId}}
                                                />
                                            </>
                                        )}
                                    </>
                                    : null
                            }
                        </>
                        : null
                }
            </SubNavigator.Navigator>
        </View>
    );
}

const mapStateToProps = (state) => {
    const {auth, miscellaneous} = state;
    return {auth, miscellaneous};
};

export default connect(mapStateToProps)(ClientPartnerSingleNavigation);
