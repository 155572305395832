import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import TeamMembersList from '../TeamMembers/TeamMembersList';
import TeamMemberSingle from '../TeamMembers/TeamMemberSingle';

const Stack = createStackNavigator();

function ClientPartnersTeamStackNavigation(props) {
	const organizationId = props.route.params.organizationId;

	return (
		<Stack.Navigator
			backBehavior="history"
			screenOptions={{
				header: () => null,
				cardStyle: {
					backgroundColor: '#F8FAFD'
				}
			}}
			initialRouteName="TeamMemberList"
		>
			<Stack.Screen
				name="TeamMembersList"
				component={TeamMembersList}
				initialParams={{organizationId}}
				options={{
					title: "Team"
				}}
			/>
			<Stack.Screen
				name="TeamMemberEdit"
				component={TeamMemberSingle}
				initialParams={{organizationId}}
				options={{
					title: "Team"
				}}
			/>
		</Stack.Navigator>
	);
}

export default ClientPartnersTeamStackNavigation;
